import { AddressModel } from './address.model'
import { AddressType } from '../enums/address'

interface Attributes {
    [CartAddressModel.ATTR_TYPE]: AddressType
    [CartAddressModel.ATTR_CUSTOMER_ADDRESS_ID]: number
}

export class CartAddressModel<TAttributes = unknown> extends AddressModel<Attributes & TAttributes> {
    static override key = 'CartAddressModel'

    static readonly ATTR_TYPE = 'type'
    static readonly ATTR_CUSTOMER_ADDRESS_ID = 'customer_address_id'

    get type() {
        return this._getAttribute(CartAddressModel.ATTR_TYPE)
    }

    get customerAddressId() {
        return this._getAttribute(CartAddressModel.ATTR_CUSTOMER_ADDRESS_ID)
    }

    isShipping() {
        return this.type === AddressType.SHIPPING
    }

    isBilling() {
        return this.type === AddressType.BILLING
    }
}
