<script lang="tsx">
import type { PropType, SlotsType, Ref } from 'vue'
import type { TabSwitcherOption } from '@core-types/components/CoreUITabSwitcher'
import type { ComponentOverrideOptions } from '@core-types/components'
import { NuxtLink } from '#components'

export type CoreUiTabSwitcherNewProps<T> = {
    /**
     * The options to display as tabs.
     */
    options: TabSwitcherOption[]
    /**
     * The ID of the element that describes the tab switcher.
     */
    ariaLabelledby?: string
    /**
     * The label of the tab switcher.
     * Only set this if the tab switcher is not described by an element.
     */
    ariaLabel?: string
    /**
      * Whether to automatically select the option in focus.
      */
    autoSelect?: boolean
    modelValue?: TabSwitcherOption | null
    /**
     * Whether the tab switcher should serve as a navigation bar instead of a tab list.
     * In this mode, the items will be rendered as NuxtLink components.
     * There is no need to adjust the selected options in this mode, since the active link will be highlighted automatically.
     */
    useLinks?: boolean
}

type CoreUiTabSwitcherNewSlots<T> = {
    label: { option: TabSwitcherOption }
}

type ComponentOptions = {}

export function defineComponentCoreUiTabSwitcher<T>(options?: ComponentOverrideOptions<ComponentOptions, CoreUiTabSwitcherNewProps<T>, CoreUiTabSwitcherNewSlots<T>>) {
    return defineComponent(
        (props: CoreUiTabSwitcherNewProps<T>, { emit, slots }) => {
            const selectedOption = ref<TabSwitcherOption | null>(props.modelValue || null)
            const tabs: Ref<HTMLButtonElement[]> = ref([])

            const router = useRouter()
            const localePath = useLocalePath()

            /**
             * Whether the provided option is selected.
             * @param option The option to check.
             */
            const isSelected = (option: TabSwitcherOption) => {
                if (props.useLinks && option.to) {
                    return router.currentRoute.value.path === localePath(option.to)
                }
                return selectedOption.value?._htmlId === option._htmlId
            }

            /**
             * Set the selected option.
             * @param option The option to set as selected.
             */
            const setSelectedOption = (option: TabSwitcherOption) => {
                selectedOption.value = option

                emit('update:modelValue', option)
            }

            /**
             * Focus the tab after the provided index.
             * Automatically wraps around to the first tab if the last tab is focused.
             * @param index The index of the reference tab (current one).
             */
            const focusNextTab = (index: number) => {
                const nextIndex = (index + 1) % props.options.length
                focusTab(nextIndex)
            }

            /**
             * Focus the tab before the provided index.
             * Automatically wraps around to the last tab if the first tab is focused.
             * @param index The index of the reference tab (current one).
             */
            const focusPreviousTab = (index: number) => {
                const previousIndex = (index - 1 + props.options.length) % props.options.length
                focusTab(previousIndex)
            }

            /**
             * Focus the tab at the provided index.
             * If `autoSelect` is enabled, the option of the tab will be selected as well.
             * @param index The index of the tab to focus.
             */
            const focusTab = (index: number) => {
                tabs.value[index]!.focus()

                if (props.autoSelect) {
                    setSelectedOption(props.options[index]!)
                }
            }

            /**
             * Handle the keydown event on the tab list.
             * This is used only for the 'Home' and 'End' keys to focus the first and last tab respectively.
             * @param event The keydown event.
             */
            const handleTabListKeydown = (event: KeyboardEvent) => {
                // focus the first tab on 'Home' press
                if (event.key === 'Home') {
                    event.preventDefault()
                    focusTab(0)
                }

                // focus the last tab on 'End' press
                if (event.key === 'End') {
                    event.preventDefault()
                    focusTab(tabs.value.length - 1)
                }
            }

            if (import.meta.dev) {
                if (props.ariaLabelledby && props.ariaLabel) {
                    errorLog('The "aria-label" and "aria-labelledby" props cannot be used together in CoreUiTabSwitcher.', getCurrentInstance()?.vnode.el)
                }
            }

            const rootTag = computed(() => props.useLinks ? 'nav' : 'div')

            return () => (
                <rootTag.value
                    class="sim-tab-switcher"
                    role={props.useLinks ? undefined : 'tablist'}
                    aria-label={props.ariaLabel ?? undefined}
                    aria-labelledby={props.ariaLabelledby ?? undefined}
                    {...{
                        ...(props.useLinks ? {} : {
                            onKeydown: handleTabListKeydown,
                        }),
                    }}
                >
                    {props.options.map((option, index) => props.useLinks
                        ? (
                            <NuxtLink
                                key={option._htmlId}
                                class={['sim-tab-switcher__tab', {
                                    'sim-tab-switcher__tab--active': isSelected(option),
                                }]}
                                to={option.to}
                            >
                                {
                                    renderSlot(slots.label, options?.slots?.label, {
                                        option: option,
                                    }, (
                                        <>
                                            {option.label}
                                        </>
                                    ))
                                }
                            </NuxtLink>
                        )
                        : (
                            <button
                                id={option._htmlId}
                                key={option._htmlId}
                                ref={(el) => tabs.value[index] = el! as HTMLButtonElement}
                                class={['sim-tab-switcher__tab', {
                                    'sim-tab-switcher__tab--active': isSelected(option),
                                }]}
                                role="tab"
                                type="button"
                                aria-selected={isSelected(option)}
                                aria-controls={`${option._htmlId}c`}
                                tabindex={isSelected(option) ? undefined : -1}
                                onClick={() => setSelectedOption(option)}
                                onKeydown={(event) => {
                                    if (event.key === 'ArrowRight') focusNextTab(index)
                                    if (event.key === 'ArrowLeft') focusPreviousTab(index)
                                }}
                            >
                                {
                                    renderSlot(slots.label, options?.slots?.label, {
                                        option: option,
                                    }, (
                                        <>
                                            {option.label}
                                        </>
                                    ))
                                }
                            </button>
                        )
                    )}
                </rootTag.value>
            )
        },
        {
            props: {
                options: {
                    type: Array as PropType<CoreUiTabSwitcherNewProps<T>['options']>,
                    required: true,
                },
                ariaLabelledby: {
                    type: String as PropType<CoreUiTabSwitcherNewProps<T>['ariaLabelledby']>,
                    default: undefined,
                    required: false,
                },
                ariaLabel: {
                    type: String as PropType<CoreUiTabSwitcherNewProps<T>['ariaLabel']>,
                    default: undefined,
                    required: false,
                },
                autoSelect: {
                    type: Boolean as PropType<CoreUiTabSwitcherNewProps<T>['autoSelect']>,
                    default: false,
                    required: false,
                },
                modelValue: {
                    type: Object as PropType<CoreUiTabSwitcherNewProps<any>['modelValue']>,
                    default: null,
                },
                useLinks: {
                    type: Boolean as PropType<CoreUiTabSwitcherNewProps<T>['useLinks']>,
                    default: false,
                    required: false,
                },
            },
            slots: Object as SlotsType<CoreUiTabSwitcherNewSlots<T>>,
            emits: ['update:modelValue'],
        }
    )
}

export default defineComponentCoreUiTabSwitcher()
</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiTabSwitcher.scss" as *;

</style>
