<template>
    <FilterBlock :title="filter.label"
                 collapsible
                 content-class="flex flex-col gap-3"
                 :expanded="expanded"
    >
        <a v-for="filterItem in filterItemsToDisplay"
           :key="filterItem.id!"
           :href="filterItem.url!"
           class="hidden"
        >
            {{ filterItem.label }}

            <UiCountIndicatorText :count="filterItem.count" type="text" />
        </a>

        <BaseUiFormCheckbox
            v-for="filterItem in filterItemsToDisplay"
            :key="filterItem.id!"
            :value="filterItem.isSelected!"
            label-class="flex items-center gap-3"
            @change="$emit('change', filterItem.url!)"
        >
            {{ filterItem.label }}

            <UiCountIndicatorText
                :count="filterItem.count"
                type="text"
            />
        </BaseUiFormCheckbox>
    </FilterBlock>
</template>

<script lang="ts" setup>

const {
    filter,
    expanded,
} = defineProps<{
    filter: InstanceType<typeof ProductFilterModel>
    expanded?: boolean
}>()

const emit = defineEmits<{
    change: [url: string]
}>()

const filterItemsToDisplay = computed(() => {
    if (!Array.isArray(filter.items)) return []

    return filter.items?.filter(item => item.shouldBeVisible()) ?? []
})

</script>

<style lang="scss" scoped>

</style>
