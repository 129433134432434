import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [SocialiteModel.ATTR_PROVIDER_AUTH_URL]: string
}

interface Embeds {
}

export class SocialiteModel extends ApiModel<Attributes, Embeds> {
    static key = 'SocialiteModel'

    static readonly ATTR_PROVIDER_AUTH_URL = 'provider_auth_url'

    get providerAuthUrl() {
        return this._getAttribute(SocialiteModel.ATTR_PROVIDER_AUTH_URL)
    }
}
