import { ApiModel } from '@composable-api/api.model'
import type { Translation } from '@sim-api-types/general-data'

interface Attributes {
    [ProductReviewParameterModel.ATTR_ID]: number
    [ProductReviewParameterModel.ATTR_NAME]: Translation<string>
    [ProductReviewParameterModel.ATTR_UPDATED_AT]: string
    [ProductReviewParameterModel.ATTR_CREATED_AT]: string
}

interface Embeds {
    [ProductReviewParameterModel.EMBED_PARAMETERS]: {
        id: number
        name: Translation<string>
        rating: number
        is_active: boolean
    }[]
}

export class ProductReviewParameterModel extends ApiModel<Attributes, Embeds> {
    static key = 'ProductReviewParameterModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_UPDATED_AT = 'updated_at'
    static readonly ATTR_CREATED_AT = 'created_at'

    static readonly EMBED_PARAMETERS = 'parameters'


    get id() {
        return this._getAttribute(ProductReviewParameterModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(ProductReviewParameterModel.ATTR_NAME)
    }

    get updatedAt() {
        return this._getAttribute(ProductReviewParameterModel.ATTR_UPDATED_AT)
    }

    get createdAt() {
        return this._getAttribute(ProductReviewParameterModel.ATTR_CREATED_AT)
    }

    get parameters() {
        return this._getEmbed(ProductReviewParameterModel.EMBED_PARAMETERS)
    }
}
