import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [GoPayModel.ATTR_ID]: number
    [GoPayModel.ATTR_ORDER_ID]: number
    [GoPayModel.ATTR_EXTERNAL_ID]: number
    [GoPayModel.ATTR_ORDER_NUMBER]: string
    [GoPayModel.ATTR_AMOUNT]: number
    [GoPayModel.ATTR_CURRENCY]: string
    [GoPayModel.ATTR_LANGUAGE]: string
    [GoPayModel.ATTR_SUCCESS]: boolean
    [GoPayModel.ATTR_GATE_URL]: string
    [GoPayModel.ATTR_PAYLOAD]: any | null
}

export class GoPayModel extends ApiModel<Attributes> {
    static key = 'GoPayModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_ORDER_ID = 'order_id'
    static readonly ATTR_EXTERNAL_ID = 'external_id'
    static readonly ATTR_ORDER_NUMBER = 'order_number'
    static readonly ATTR_AMOUNT = 'amount'
    static readonly ATTR_CURRENCY = 'currency'
    static readonly ATTR_LANGUAGE = 'language'
    static readonly ATTR_SUCCESS = 'success'
    static readonly ATTR_GATE_URL = 'gate_url'
    static readonly ATTR_PAYLOAD = 'payload'

    get id() {
        return this._getAttribute(GoPayModel.ATTR_ID)
    }

    get orderId() {
        return this._getAttribute(GoPayModel.ATTR_ORDER_ID)
    }

    get externalId() {
        return this._getAttribute(GoPayModel.ATTR_EXTERNAL_ID)
    }

    get orderNumber() {
        return this._getAttribute(GoPayModel.ATTR_ORDER_NUMBER)
    }

    get amount() {
        return this._getAttribute(GoPayModel.ATTR_AMOUNT)
    }

    get currency() {
        return this._getAttribute(GoPayModel.ATTR_CURRENCY)
    }

    get language() {
        return this._getAttribute(GoPayModel.ATTR_LANGUAGE)
    }

    get success() {
        return this._getAttribute(GoPayModel.ATTR_SUCCESS)
    }

    get gateUrl() {
        return this._getAttribute(GoPayModel.ATTR_GATE_URL)
    }

    get payload() {
        return this._getAttribute(GoPayModel.ATTR_PAYLOAD)
    }
}
