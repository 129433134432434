import { AddressModel } from './address.model'

interface Attributes {
    [CustomerAddressModel.ATTR_IS_BILLING_DEFAULT]: boolean
    [CustomerAddressModel.ATTR_IS_SHIPPING_DEFAULT]: boolean
}

export class CustomerAddressModel extends AddressModel<Attributes> {
    static override key = 'CustomerAddressModel'

    static readonly ATTR_IS_BILLING_DEFAULT = 'is_billing_default'
    static readonly ATTR_IS_SHIPPING_DEFAULT = 'is_shipping_default'

    get isBillingDefault() {
        return this._getAttribute(CustomerAddressModel.ATTR_IS_BILLING_DEFAULT)
    }

    get isShippingDefault() {
        return this._getAttribute(CustomerAddressModel.ATTR_IS_SHIPPING_DEFAULT)
    }
}
