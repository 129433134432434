import { ApiModel } from '@composable-api/api.model'
import { ArticleCategoryModel } from './article-category.model'
import { ArticleAuthorModel } from '@simploshop-models/custom/article-author.model'

interface Attributes {
    [ArticleModel.ATTR_ID]: number
    [ArticleModel.ATTR_NAME]: string | null
    [ArticleModel.ATTR_PEREX]: string
    [ArticleModel.ATTR_CONTENT]: string
    [ArticleModel.ATTR_DEFAULT_ARTICLE_CATEGORY_ID]: number | null
    [ArticleModel.ATTR_SLUG]: string
    [ArticleModel.ATTR_TIME_FOR_READING]: number | null
    [ArticleModel.ATTR_PUBLISH_AT]: string
}

interface Embeds {
    [ArticleModel.EMBED_URLS]: string
    [ArticleModel.EMBED_USER]: ArticleAuthorModel
    [ArticleModel.EMBED_IMAGE_URL]: string
    [ArticleModel.EMBED_DEFAULT_ARTICLE_CATEGORY]: ArticleCategoryModel
    [ArticleModel.EMBED_ARTICLE_CATEGORY_IDS]: number[]
    [ArticleModel.EMBED_PRODUCT_IDS]: number[]
    [ArticleModel.EMBED_TAGS]: string
    [ArticleModel.EMBED_ARTICLE_PREV]: AssociatedArticle
    [ArticleModel.EMBED_ARTICLE_NEXT]: AssociatedArticle
}

interface Filters {
    [ArticleModel.FILTER_ARTICLE_CATEGORY_IDS]: any
}

export class ArticleModel extends ApiModel<Attributes, Embeds, Filters> {
    static key = 'ArticleModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_PEREX = 'perex'
    static readonly ATTR_CONTENT = 'content'
    static readonly ATTR_DEFAULT_ARTICLE_CATEGORY_ID = 'default_article_category_id'
    static readonly ATTR_SLUG = 'slug'
    static readonly ATTR_TIME_FOR_READING = 'time_for_reading'
    static readonly ATTR_PUBLISH_AT = 'publish_at'

    static readonly EMBED_URLS = 'urls'
    static readonly EMBED_USER = 'user'
    static readonly EMBED_IMAGE_URL = 'image_url'
    static readonly EMBED_DEFAULT_ARTICLE_CATEGORY = 'default_article_category'
    static readonly EMBED_ARTICLE_CATEGORY_IDS = 'article_category_ids'
    static readonly EMBED_PRODUCT_IDS = 'product_ids'
    static readonly EMBED_TAGS = 'tags'
    static readonly EMBED_ARTICLE_PREV = 'article_prev'
    static readonly EMBED_ARTICLE_NEXT = 'article_next'

    static readonly FILTER_ARTICLE_CATEGORY_IDS = 'article_category_ids'

    get id() {
        return this._getAttribute(ArticleModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(ArticleModel.ATTR_NAME)
    }

    get perex() {
        return this._getAttribute(ArticleModel.ATTR_PEREX)
    }

    get content() {
        return this._getAttribute(ArticleModel.ATTR_CONTENT)
    }

    get defaultArticleCategoryId() {
        return this._getAttribute(ArticleModel.ATTR_DEFAULT_ARTICLE_CATEGORY_ID)
    }

    get slug() {
        return this._getAttribute(ArticleModel.ATTR_SLUG)
    }

    get timeForReading() {
        return this._getAttribute(ArticleModel.ATTR_TIME_FOR_READING)
    }

    get publishAt() {
        return this._getAttribute(ArticleModel.ATTR_PUBLISH_AT)
    }

    get urls() {
        return this._getEmbed(ArticleModel.EMBED_URLS)
    }

    get user() {
        return this._getEmbed(ArticleModel.EMBED_USER, ArticleAuthorModel)
    }

    get imageUrl() {
        return this._getEmbed(ArticleModel.EMBED_IMAGE_URL)
    }

    get defaultArticleCategory() {
        return this._getEmbed(ArticleModel.EMBED_DEFAULT_ARTICLE_CATEGORY, ArticleCategoryModel)
    }

    get articleCategoryIds() {
        return this._getEmbed(ArticleModel.EMBED_ARTICLE_CATEGORY_IDS)
    }

    get productIds() {
        return this._getEmbed(ArticleModel.EMBED_PRODUCT_IDS)
    }

    get tags() {
        return this._getEmbed(ArticleModel.EMBED_TAGS)
    }

    get articlePrev() {
        return this._getEmbed(ArticleModel.EMBED_ARTICLE_PREV)
    }

    get articleNext() {
        return this._getEmbed(ArticleModel.EMBED_ARTICLE_NEXT)
    }
}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================

interface AssociatedArticle {
    name: string
    urls: string
    image_url: string
}
