import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [UserModel.ATTR_ID]: number
    [UserModel.ATTR_FIRST_NAME]: string
    [UserModel.ATTR_LAST_NAME]: string
}

export class UserModel extends ApiModel<Attributes> {
    static key = 'UserModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_FIRST_NAME = 'first_name'
    static readonly ATTR_LAST_NAME = 'last_name'

    get id() {
        return this._getAttribute(UserModel.ATTR_ID)
    }

    get firstName() {
        return this._getAttribute(UserModel.ATTR_FIRST_NAME)
    }

    get lastName() {
        return this._getAttribute(UserModel.ATTR_LAST_NAME)
    }

    // ---------------------

    /**
     * Get the full name of the user.
     * In case the first name and the last name are both set, the full name will be returned.
     *
     * If only one of them is set, that one will be returned.
     *
     * Otherwise, `null` will be returned.
     */
    getFullName() {
        return this.firstName && this.lastName
            ? `${this.firstName} ${this.lastName}`
            : this.firstName || this.lastName || null
    }
}
