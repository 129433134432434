<template>
    <div class="sim-full-layout">
        <CoreSkipToMainContent />

        <!--  NAVBAR  -->
        <NavbarComponent :type="navbarType" />

        <slot />

        <!--  FOOTER  -->
        <FooterComponent class="mt-auto" />
    </div>
</template>

<script lang="ts" setup>
import type { PageMeta } from '#app'

const route = useRoute()
const { page } = useCurrentPage()

const {
    layoutProps: _layoutProps,
} = defineProps<{
    layoutProps?: PageMeta['layoutProps']
}>()

const layoutProps = computed(() => _layoutProps ?? route.meta.layoutProps)

const navbarType = computed(() => {
    if (layoutProps.value?.navbarType) {
        return layoutProps.value.navbarType
    }
    return undefined
})

const { t } = useI18n()

useHead({
    script: [
        {
            innerHTML: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-K6PPGSVG');
            `,
        },
    ],
    noscript: [
        {
            innerHTML: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K6PPGSVG"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
            `,
            tagPosition: 'bodyOpen',
        },
    ],
    link: [
        // Preconnect to the image CDN
        {
            rel: 'preconnect',
            href: 'https://demo.simploshop.com',
        },
    ],
})

useSetPageSeoProperties(page, {
    title: 'Demo | simploSHOP',
    description: t('meta.description'),
})

</script>

<style lang="scss" scoped>

.sim-full-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: 100dvh;
}

</style>
