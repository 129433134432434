<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="30" fill="none">
        <path fill="#C00" fill-rule="evenodd"
            d="M14.794 1.65c-.848.136-1.691.293-2.53.474-.556.117-1.11.226-1.659.363-.505.127-1.024.24-1.52.397-.478.147-.952.308-1.42.485a9.11 9.11 0 0 0-2.527 1.474c-.46.384-.889.8-1.249 1.28A9.053 9.053 0 0 0 3.22 7.2c-.192.358-.323.78-.328 1.19-.006 1.204.763 2.213 1.65 2.962.29.244.6.47.914.684.932.64 1.946 1.202 2.976 1.69a87.22 87.22 0 0 0 3.218 1.446c1.024.434 2.07.8 3.128 1.15l.915.298c.958.316 1.936.609 2.887.951.592.213 1.223.412 1.814.678.614.276 1.252.55 1.794.947.364.259.673.588.908.968.228.375.37.805.38 1.243.01.42-.107.835-.338 1.187-.594.923-1.628 1.553-2.625 1.972a43.084 43.084 0 0 1-4.57 1.619c-.884.258-1.77.514-2.661.744-.895.232-1.808.474-2.712.7-.902.226-1.804.429-2.709.623-.519.11-1.04.218-1.556.325-.31.062-.614.127-.925.194-.648.14-1.295.28-1.945.417-.74.162-1.48.32-2.22.48-.216.047-.43.104-.645.152-.192.043-.908.209-.376.177.74-.04 2.716-.212 6.936-.773 1.53-.252 3.066-.467 4.598-.716a82.246 82.246 0 0 0 5.69-1.117 48.814 48.814 0 0 0 1.609-.407 34.95 34.95 0 0 0 1.05-.306c1.48-.456 3.854-1.26 4.416-1.503 2.148-.934 3.485-1.637 4.182-3.31.238-.595.35-1.26.278-1.898a4.292 4.292 0 0 0-.578-1.68c-.294-.484-.697-.898-1.117-1.28-.783-.709-1.661-1.28-2.6-1.766a16.498 16.498 0 0 0-.808-.391 24.3 24.3 0 0 0-2.495-.987c-1.288-.439-2.596-.83-3.891-1.249a27.562 27.562 0 0 1-2.168-.792c-.3-.124-.595-.257-.888-.399a13.894 13.894 0 0 1-1.008-.54c-.405-.243-1.07-.662-.885-1.232.13-.406.575-.564.939-.71a8.67 8.67 0 0 1 1.043-.32c1.071-.274 2.164-.458 3.244-.686.318-.067.635-.136.952-.209.842-.196 1.71-.342 2.514-.66.69-.269 1.575-.699 1.833-1.45.37-1.095-.05-2.278-.654-3.201a32.13 32.13 0 0 0-.674-.98c-.317-.441-.668-.856-1.01-1.275-.156-.19-.345-.176-.504-.128-.21.072-.788.743-5.403 1.62Z"
            clip-rule="evenodd"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
