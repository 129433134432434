import { ApiModel } from '@composable-api/api.model'
import type { GenderTypeEnum } from '../enums/customer'
import { CartShippingMethodModel } from './cart-shipping-method.model'
import { CartPaymentMethodModel } from './cart-payment-method.model'

// TODO: verify that the types are correct
interface Attributes {
    [CustomerModel.ATTR_ID]: number
    [CustomerModel.ATTR_FIRST_NAME]: string
    [CustomerModel.ATTR_LAST_NAME]: string
    [CustomerModel.ATTR_EMAIL]: string
    [CustomerModel.ATTR_PHONE]: string | null
    [CustomerModel.ATTR_IS_ACTIVE]: boolean
    [CustomerModel.ATTR_LAST_LOGGED_AT]: string
    [CustomerModel.ATTR_IS_BLOCKED]: boolean
    [CustomerModel.ATTR_BANK_CODE]: string | null
    [CustomerModel.ATTR_BANK_ACCOUNT_NUMBER]: string | null
    [CustomerModel.ATTR_BANK_ACCOUNT_IBAN]: string | null
    [CustomerModel.ATTR_BANK_SWIFT_CODE]: string | null
    [CustomerModel.ATTR_RESTRICT_TO_COUNTRY_ID]: number | null
    [CustomerModel.ATTR_GENDER_ORIENTED_PRODUCT_PREFERENCE]: GenderTypeEnum | null
    [CustomerModel.ATTR_ADDITIONAL_PARAMS]: string | null
    [CustomerModel.ATTR_CODE]: string | null
    [CustomerModel.ATTR_BIRTH_AT]: string | null
    [CustomerModel.ATTR_PREFERRED_PAYMENT_METHOD_ID]: number | null
    [CustomerModel.ATTR_PREFERRED_SHIPPING_METHOD_ID]: number | null
    [CustomerModel.ATTR_CREATED_AT]: string
}

interface Embeds {
    [CustomerModel.EMBED_PREFERRED_PAYMENT_METHOD]: CartPaymentMethodModel
    [CustomerModel.EMBED_PREFERRED_SHIPPING_METHOD]: CartShippingMethodModel
}

export class CustomerModel extends ApiModel<Attributes, Embeds> {
    static key = 'CustomerModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_FIRST_NAME = 'first_name'
    static readonly ATTR_LAST_NAME = 'last_name'
    static readonly ATTR_EMAIL = 'email'
    static readonly ATTR_PHONE = 'phone'
    static readonly ATTR_IS_ACTIVE = 'is_active'
    static readonly ATTR_LAST_LOGGED_AT = 'last_logged_at'
    static readonly ATTR_IS_BLOCKED = 'is_blocked'
    static readonly ATTR_BANK_CODE = 'bank_code'
    static readonly ATTR_BANK_ACCOUNT_NUMBER = 'bank_account_number'
    static readonly ATTR_BANK_ACCOUNT_IBAN = 'bank_account_iban'
    static readonly ATTR_BANK_SWIFT_CODE = 'bank_swift_code'
    static readonly ATTR_RESTRICT_TO_COUNTRY_ID = 'restrict_to_country_id'
    static readonly ATTR_GENDER_ORIENTED_PRODUCT_PREFERENCE = 'gender_oriented_product_preference'
    static readonly ATTR_ADDITIONAL_PARAMS = 'additional_params'
    static readonly ATTR_CODE = 'code'
    static readonly ATTR_BIRTH_AT = 'birth_at'
    static readonly ATTR_PREFERRED_PAYMENT_METHOD_ID = 'preferred_payment_method_id'
    static readonly ATTR_PREFERRED_SHIPPING_METHOD_ID = 'preferred_shipping_method_id'
    static readonly ATTR_CREATED_AT = 'created_at'

    static readonly EMBED_PREFERRED_PAYMENT_METHOD = 'preferred_payment_method'
    static readonly EMBED_PREFERRED_SHIPPING_METHOD = 'preferred_shipping_method'

    get id() {
        return this._getAttribute(CustomerModel.ATTR_ID)
    }

    get firstName() {
        return this._getAttribute(CustomerModel.ATTR_FIRST_NAME)
    }

    get lastName() {
        return this._getAttribute(CustomerModel.ATTR_LAST_NAME)
    }

    get email() {
        return this._getAttribute(CustomerModel.ATTR_EMAIL)
    }

    get phone() {
        return this._getAttribute(CustomerModel.ATTR_PHONE)
    }

    get isActive() {
        return this._getAttribute(CustomerModel.ATTR_IS_ACTIVE)
    }

    get lastLoggedAt() {
        return this._getAttribute(CustomerModel.ATTR_LAST_LOGGED_AT)
    }

    get isBlocked() {
        return this._getAttribute(CustomerModel.ATTR_IS_BLOCKED)
    }

    get bankCode() {
        return this._getAttribute(CustomerModel.ATTR_BANK_CODE)
    }

    get bankAccountNumber() {
        return this._getAttribute(CustomerModel.ATTR_BANK_ACCOUNT_NUMBER)
    }

    get bankAccountIban() {
        return this._getAttribute(CustomerModel.ATTR_BANK_ACCOUNT_IBAN)
    }

    get bankSwiftCode() {
        return this._getAttribute(CustomerModel.ATTR_BANK_SWIFT_CODE)
    }

    get restrictToCountryId() {
        return this._getAttribute(CustomerModel.ATTR_RESTRICT_TO_COUNTRY_ID)
    }

    get genderOrientedProductPreference() {
        return this._getAttribute(CustomerModel.ATTR_GENDER_ORIENTED_PRODUCT_PREFERENCE)
    }

    get additionalParams() {
        return this._getAttribute(CustomerModel.ATTR_ADDITIONAL_PARAMS)
    }

    get code() {
        return this._getAttribute(CustomerModel.ATTR_CODE)
    }

    get birthAt() {
        return this._getAttribute(CustomerModel.ATTR_BIRTH_AT)
    }

    get preferredPaymentMethodId() {
        return this._getAttribute(CustomerModel.ATTR_PREFERRED_PAYMENT_METHOD_ID)
    }

    get preferredShippingMethodId() {
        return this._getAttribute(CustomerModel.ATTR_PREFERRED_SHIPPING_METHOD_ID)
    }

    get createdAt() {
        return this._getAttribute(CustomerModel.ATTR_CREATED_AT)
    }

    get preferredPaymentMethod() {
        return this._getEmbed(CustomerModel.EMBED_PREFERRED_PAYMENT_METHOD, CartPaymentMethodModel)
    }

    get preferredShippingMethod() {
        return this._getEmbed(CustomerModel.EMBED_PREFERRED_SHIPPING_METHOD, CartShippingMethodModel)
    }

    // ---------------------

    /**
     * Get the full name of the customer.
     * In case the first name and the last name are both set, the full name will be returned.
     *
     * If only one of them is set, that one will be returned.
     *
     * Otherwise, `null` will be returned.
     */
    getFullName() {
        return this.firstName && this.lastName
            ? `${this.firstName} ${this.lastName}`
            : this.firstName || this.lastName || null
    }
}
