import type { ApiDateTime } from '../../types/general-data'
import { ApiModel } from '@composable-api/api.model'

interface ProductVariationPropertyAttributeModelAttributes {
    [ProductVariationPropertyAttributeModel.ATTR_ID]: number
    [ProductVariationPropertyAttributeModel.ATTR_PRODUCT_PROPERTY_ID]: number
    [ProductVariationPropertyAttributeModel.ATTR_NAME]: string
    [ProductVariationPropertyAttributeModel.ATTR_POSITION]: number
    [ProductVariationPropertyAttributeModel.ATTR_IS_HIDDEN]: boolean
    [ProductVariationPropertyAttributeModel.ATTR_IMAGE_ID]: number | null
    [ProductVariationPropertyAttributeModel.ATTR_IMAGE_URL]: string | null
    [ProductVariationPropertyAttributeModel.ATTR_HAS_AUTOMATIC_SLUG]: boolean
    [ProductVariationPropertyAttributeModel.ATTR_SLUG]: string
    [ProductVariationPropertyAttributeModel.ATTR_VALUE]: string | null
    [ProductVariationPropertyAttributeModel.ATTR_CREATED_AT]: ApiDateTime
}

export class ProductVariationPropertyAttributeModel extends ApiModel<ProductVariationPropertyAttributeModelAttributes> {
    static key = 'ProductVariationPropertyAttributeModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_PRODUCT_PROPERTY_ID = 'product_property_id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_POSITION = 'position'
    static readonly ATTR_IS_HIDDEN = 'is_hidden'
    static readonly ATTR_IMAGE_ID = 'image_id'
    static readonly ATTR_IMAGE_URL = 'image_url'
    static readonly ATTR_HAS_AUTOMATIC_SLUG = 'has_automatic_slug'
    static readonly ATTR_SLUG = 'slug'
    static readonly ATTR_VALUE = 'value'
    static readonly ATTR_CREATED_AT = 'created_at'

    get id() {
        return this._getAttribute(ProductVariationPropertyAttributeModel.ATTR_ID)
    }

    get productPropertyId() {
        return this._getAttribute(ProductVariationPropertyAttributeModel.ATTR_PRODUCT_PROPERTY_ID)
    }

    get name() {
        return this._getAttribute(ProductVariationPropertyAttributeModel.ATTR_NAME)
    }

    get position() {
        return this._getAttribute(ProductVariationPropertyAttributeModel.ATTR_POSITION)
    }

    get isHidden() {
        return this._getAttribute(ProductVariationPropertyAttributeModel.ATTR_IS_HIDDEN)
    }

    private get imageId() {
        return this._getAttribute(ProductVariationPropertyAttributeModel.ATTR_IMAGE_ID)
    }

    get imageUrl() {
        return this._getAttribute(ProductVariationPropertyAttributeModel.ATTR_IMAGE_URL)
    }

    get hasAutomaticSlug() {
        return this._getAttribute(ProductVariationPropertyAttributeModel.ATTR_HAS_AUTOMATIC_SLUG)
    }

    get slug() {
        return this._getAttribute(ProductVariationPropertyAttributeModel.ATTR_SLUG)
    }

    get value() {
        return this._getAttribute(ProductVariationPropertyAttributeModel.ATTR_VALUE)
    }

    get createdAt() {
        return this._getAttribute(ProductVariationPropertyAttributeModel.ATTR_CREATED_AT)
    }

    // ------------------------------------------------------------

    getKey() {
        return `${this.id}-${this.productPropertyId}`
    }

    /**
     * Check if the attribute is equal to another attribute.
     * @param other the other attribute to compare with
     */
    equals(other: ProductVariationPropertyAttributeModel | null | undefined) {
        return this.id === other?.id && this.productPropertyId === other.productPropertyId
    }
}
