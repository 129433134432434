import type { Monetary } from '../types/general-data'
import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [CartDiscountModel.ATTR_ID]: number
    [CartDiscountModel.ATTR_NAME]: string | null
    [CartDiscountModel.ATTR_CODE]: string | null
    [CartDiscountModel.ATTR_AMOUNT]: Monetary | null
    [CartDiscountModel.ATTR_TAXED_AMOUNT]: Monetary | null
}

export class CartDiscountModel extends ApiModel<Attributes> {
    static key = 'CartDiscountModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_CODE = 'code'
    static readonly ATTR_AMOUNT = 'amount'
    static readonly ATTR_TAXED_AMOUNT = 'taxed_amount'

    get id() {
        return this._getAttribute(CartDiscountModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(CartDiscountModel.ATTR_NAME)
    }

    get code() {
        return this._getAttribute(CartDiscountModel.ATTR_CODE)
    }

    get amount() {
        return this._getAttribute(CartDiscountModel.ATTR_AMOUNT)
    }

    get taxedAmount() {
        return this._getAttribute(CartDiscountModel.ATTR_TAXED_AMOUNT)
    }

    // ---------------------------------------------------------------------------------------------------------------------
}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================
