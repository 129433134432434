<template>
    <span class="sim-cart-phase-indicator"
          :class="{
              'sim-cart-phase-indicator--filled': filled }"
    >
        <span class="sim-cart-phase-indicator__icon-box">
            <component
                :is="iconComponent"
                v-if="iconComponent"
                class="sim-cart-phase-indicator__icon"
                aria-hidden="true"
            />
        </span>
        <span class="sim-text-small text-basic-600 text-center">
            <slot name="label">
                {{ label }}
            </slot>
        </span>
    </span>
</template>

<script lang="ts" setup>
const {
    icon,
    label,
    filled,
} = defineProps<{
    icon: string | undefined | null
    label: string
    filled: boolean
}>()

const iconComponent = icon ? defineAsyncComponent(() => import(`../../icon/${icon}.vue`)) : null

</script>

<style lang="scss" scoped>
.sim-cart-phase-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.625rem;

    width: 4.6875rem;

    color: $sim-c-basic-500;

    z-index: 1;

    @include more-than(sm) {
        min-width: 6.875rem;
    }
}

.sim-cart-phase-indicator--filled {
    color: $sim-c-primary-800;
}

.sim-cart-phase-indicator__icon-box {
    width: 2rem;
    height: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 0.1125rem solid $sim-c-basic-400;
    background-color: white;
    border-radius: 100%;

    transition: color $sim-trans-time-normal $sim-timing, border-color $sim-trans-time-normal $sim-timing, background-color $sim-trans-time-normal $sim-timing;

    @include more-than(sm) {
        width: 4rem;
        height: 4rem;
    }
}

.sim-cart-phase-indicator:hover .sim-cart-phase-indicator__icon-box {
    color: $sim-c-primary-400;
    border-color: $sim-c-primary-400;
    background-color: $sim-c-white;
}

.sim-cart-phase-indicator--filled .sim-cart-phase-indicator__icon-box {
    color: $sim-c-white;
    background-color: $sim-c-primary-800;
    border-color: $sim-c-primary-800;
}

.sim-cart-phase-indicator__icon {
    width: 1.125rem;
    height: 1.125rem;

    @include more-than(sm) {
        width: 2.25rem;
        height: 2.25rem;
    }
}
</style>
