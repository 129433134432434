import { ApiModel } from '@composable-api/api.model'
import { CustomerModel } from '../models/customer.model'
import { CustomerProductListModel } from '@simploshop-models/customer-product-list.model'

interface Attributes {
    [MeModel.ATTR_CUSTOMER]?: CustomerModel | null
    [MeModel.ATTR_IS_GUEST]: boolean
}

interface Embeds {
    [MeModel.EMBED_CUSTOMER_LATEST_CART_ID]: string | null
    [MeModel.EMBED_CUSTOMER_ACCOUNT_AMOUNTS]: unknown[]
    [MeModel.EMBED_CUSTOMER_USED_REFERRAL_CODE]: unknown
    [MeModel.EMBED_NAME_INFLECTIONS]: unknown | null   // TODO: add the correct type
    [MeModel.EMBED_CUSTOMER_PRODUCT_WISH_LIST]: CustomerProductListModel | null
}

export class MeModel extends ApiModel<Attributes, Embeds> {
    static key = 'MeModel'

    static readonly ATTR_CUSTOMER = 'customer'
    static readonly ATTR_IS_GUEST = 'is_guest'

    static readonly EMBED_CUSTOMER_LATEST_CART_ID = 'customer_latest_cart_id'
    static readonly EMBED_CUSTOMER_ACCOUNT_AMOUNTS = 'customer_account_amounts'
    static readonly EMBED_CUSTOMER_USED_REFERRAL_CODE = 'customer_used_referral_code'
    static readonly EMBED_NAME_INFLECTIONS = 'name_inflections'
    static readonly EMBED_CUSTOMER_PRODUCT_WISH_LIST = 'customer_product_wish_list'

    /**
     * The customer model of the user.
     * If the user is not logged in, this will be `null`.
     * @returns The `CustomerModel` of the user or `null` if the user is not logged in.
     */
    get customer() {
        return this._getAttribute(MeModel.ATTR_CUSTOMER, CustomerModel)
    }

    set customer(value: CustomerModel | null) {
        this._setAttribute(MeModel.ATTR_CUSTOMER, value?._toJSON() as any)
    }

    /**
     * Whether the user is logged in or not.
     * @returns **`false`** if the user **is logged in**, `true` otherwise.
     */
    get isGuest() {
        return this._getAttribute(MeModel.ATTR_IS_GUEST)
    }

    /**
     * The latest cart id of the customer. Only available if the user is logged in.
     * This can be useful not to have to fetch the cart separately.
     * Is `null` for guests.
     */
    get latestCartId() {
        return this._getEmbed(MeModel.EMBED_CUSTOMER_LATEST_CART_ID)
    }

    get customerProductWishList() {
        return this._getEmbed(MeModel.EMBED_CUSTOMER_PRODUCT_WISH_LIST, CustomerProductListModel)
    }
}
