import { AddressModel } from './address.model'
import { AddressType } from '../enums/address'
import { ShippingServiceType } from '../enums/shipping'

interface Attributes {
    [OrderAddressModel.ATTR_ORDER_ID]: number
    [OrderAddressModel.ATTR_TYPE]: AddressType
    [OrderAddressModel.ATTR_CUSTOMER_ADDRESS_ID]: number | null
    [OrderAddressModel.ATTR_SHIPPING_METHOD_ID]: number | null
    [OrderAddressModel.ATTR_SHIPPING_OPTION_ID]: string | null
    [OrderAddressModel.ATTR_SHIPPING_SERVICE]: ShippingServiceType
}

interface Embeds {
}

export class OrderAddressModel extends AddressModel<Attributes, Embeds> {
    static override key = 'OrderAddressModel'

    static readonly ATTR_ORDER_ID = 'order_id'
    static readonly ATTR_TYPE = 'type'
    static readonly ATTR_CUSTOMER_ADDRESS_ID = 'customer_address_id'
    static readonly ATTR_SHIPPING_METHOD_ID = 'shipping_method_id'
    static readonly ATTR_SHIPPING_OPTION_ID = 'shipping_option_id'
    static readonly ATTR_SHIPPING_SERVICE = 'shipping_service'

    get orderId() {
        return this._getAttribute(OrderAddressModel.ATTR_ORDER_ID)
    }

    get type() {
        return this._getAttribute(OrderAddressModel.ATTR_TYPE)
    }

    get customerAddressId() {
        return this._getAttribute(OrderAddressModel.ATTR_CUSTOMER_ADDRESS_ID)
    }

    get shippingMethodId() {
        return this._getAttribute(OrderAddressModel.ATTR_SHIPPING_METHOD_ID)
    }

    get shippingOptionId() {
        return this._getAttribute(OrderAddressModel.ATTR_SHIPPING_OPTION_ID)
    }

    get shippingService() {
        return this._getAttribute(OrderAddressModel.ATTR_SHIPPING_SERVICE)
    }

    isShipping() {
        return this.type === AddressType.SHIPPING
    }

    isBilling() {
        return this.type === AddressType.BILLING
    }
}
