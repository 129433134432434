export enum CustomerProductListType {
    WISH_LIST = 1,
    PRODUCTS_COMPARISON = 2,
}

export enum SocialiteTypeEnum {
    GOOGLE = 'google',
    SEZNAM = 'seznam',
    VUT = 'vut',
}

export enum GenderTypeEnum {
    MALE = 0,
    FEMALE = 1,
}