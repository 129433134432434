import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [AddressModel.ATTR_ID]: number
    [AddressModel.ATTR_FIRST_NAME]: string
    [AddressModel.ATTR_LAST_NAME]: string
    [AddressModel.ATTR_PHONE]: string
    [AddressModel.ATTR_DIAL_CODE]: string | null
    [AddressModel.ATTR_STREET]: string
    [AddressModel.ATTR_CITY]: string
    [AddressModel.ATTR_POST_CODE]: string
    [AddressModel.ATTR_COUNTRY_ID]: string
    [AddressModel.ATTR_IS_COMPANY]: boolean
    [AddressModel.ATTR_COMPANY_NAME]: string | null
    [AddressModel.ATTR_COMPANY_IDENTIFICATION_NUMBER]: string | null
    [AddressModel.ATTR_COMPANY_VAT_NUMBER]: string | null
    [AddressModel.ATTR_CREATED_AT]: string
}

export class AddressModel<TAttributes = unknown, TEmbeds extends Record<string, any> = Record<string, any>> extends ApiModel<Attributes & TAttributes, TEmbeds> {
    static key = 'AddressModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_FIRST_NAME = 'first_name'
    static readonly ATTR_LAST_NAME = 'last_name'
    static readonly ATTR_PHONE = 'phone'
    static readonly ATTR_DIAL_CODE = 'dial_code'
    static readonly ATTR_STREET = 'street'
    static readonly ATTR_CITY = 'city'
    static readonly ATTR_POST_CODE = 'post_code'
    static readonly ATTR_COUNTRY_ID = 'country_id'
    static readonly ATTR_IS_COMPANY = 'is_company'
    static readonly ATTR_COMPANY_NAME = 'company_name'
    static readonly ATTR_COMPANY_IDENTIFICATION_NUMBER = 'company_identification_number'
    static readonly ATTR_COMPANY_VAT_NUMBER = 'company_vat_number'
    static readonly ATTR_CREATED_AT = 'created_at'

    get id() {
        return this._getAttribute(AddressModel.ATTR_ID)
    }

    get firstName() {
        return this._getAttribute(AddressModel.ATTR_FIRST_NAME)
    }

    get lastName() {
        return this._getAttribute(AddressModel.ATTR_LAST_NAME)
    }

    get phone() {
        return this._getAttribute(AddressModel.ATTR_PHONE)
    }

    get dialCode() {
        return this._getAttribute(AddressModel.ATTR_DIAL_CODE)
    }

    get street() {
        return this._getAttribute(AddressModel.ATTR_STREET)
    }

    get city() {
        return this._getAttribute(AddressModel.ATTR_CITY)
    }

    get postCode() {
        return this._getAttribute(AddressModel.ATTR_POST_CODE)
    }

    get countryId() {
        return this._getAttribute(AddressModel.ATTR_COUNTRY_ID)
    }

    get isCompany() {
        return this._getAttribute(AddressModel.ATTR_IS_COMPANY)
    }

    get companyName() {
        return this._getAttribute(AddressModel.ATTR_COMPANY_NAME)
    }

    get companyIdentificationNumber() {
        return this._getAttribute(AddressModel.ATTR_COMPANY_IDENTIFICATION_NUMBER)
    }

    get companyVatNumber() {
        return this._getAttribute(AddressModel.ATTR_COMPANY_VAT_NUMBER)
    }

    get createdAt() {
        return this._getAttribute(AddressModel.ATTR_CREATED_AT)
    }

    getFullName() {
        return this.firstName && this.lastName
            ? `${this.firstName} ${this.lastName}`
            : this.firstName || this.lastName || null
    }

    getFormattedAddress() {
        return `${this.street}, ${this.postCode} ${this.city}`
    }
}
