<template>
    <NuxtLayout name="bare">
        <CoreContainer class="mx-auto max-w-7xl">
            <!--  HEADER SECTION  -->
            <BaseContainerContent
                v-if="isCartLayoutVisible || isFreeShippingProgressVisible"
                class="max-sm:!pt-8"
                :padding="{ top: 'large', bottom: 'max-sm:none' }"
            >
                <!--  CART PHASE INDICATOR  -->
                <CartPhaseProgressBar
                    v-if="isCartLayoutVisible"
                />

                <!--  FREE SHIPPING PROGRESS  -->
                <CartFreeShippingProgress
                    v-if="isFreeShippingProgressVisible && freeShippingPrice?.value"
                    class="mt-8 sm:mt-24"
                    :current="getPriceValue(cart?.discountedTaxedItemsPrice) ?? 0"
                    :target="freeShippingPrice.value"
                    :currency-symbol="currencySymbol"
                />
            </BaseContainerContent>

            <BaseContainerContent
                class="sim-l-cart"
                :padding="{
                    top: !isCartLayoutVisible ? 'large' : undefined,
                    bottom: 'large',
                }"
            >
                <!--  MAIN CONTENT  -->
                <div :class="{
                    'col-span-3': !isCartSummaryVisible,
                    'col-span-3 row-span-2 lg:col-span-2': isCartSummaryVisible,
                }"
                >
                    <slot />
                </div>

                <!--  SUMMARY  -->
                <CartSummary v-if="isCartSummaryVisible && isCartLayoutVisible"
                             class="w-full max-lg:col-span-3 max-lg:row-start-1 lg:col-start-3"
                />

                <!--  BUTTON  -->
                <div v-if="!showNoItemsMessage" class="max-lg:col-span-3 lg:col-start-3">
                    <BaseUiButton
                        v-if="isCartLayoutVisible"
                        color="primary"
                        size="large"
                        class="w-full"
                        :on-click="isLastCartPhase ? submitCartOrder : undefined"
                        :to="isLastCartPhase ? undefined : localePath({ name: nextPhase?.routeName })"
                        :disabled="isLastCartPhase ? undefined : !canSwitchTo(nextPhase)"
                    >

                        {{ $t(nextPhase?.label ?? '') }}

                        <template #trailing>
                            <IconChevron size="xs" left />
                        </template>
                    </BaseUiButton>

                    <BaseUiFormError v-if="errorMessage && canErrorMessageBeShown" class="mt-2">
                        {{ errorMessage }}
                    </BaseUiFormError>
                </div>
            </BaseContainerContent>

        </CoreContainer>
    </NuxtLayout>
</template>

<script lang="ts" setup>

import { getPriceValue } from '@sim-api-utils/localization'
import { EcoMailTag } from '../assets/ts/enums/eco-mail'

const localePath = useLocalePath()

const { cart, items, fetchItems, phase, isPhaseActive, isPhaseCompleted, nextPhase, canSwitchTo, submitOrder, isLastCartPhase } = useCart()
const { freeShippingPrice, currencySymbol } = useProperties()

const isCartLayoutVisible = computed<boolean>(() => !isPhaseActive(CartPhase.SUMMARY))
const isFreeShippingProgressVisible = computed<boolean>(() => isPhaseActive(CartPhase.DETAIL) && !!items.value?.length)
const showNoItemsMessage = computed<boolean>(() => !items.value?.length)
const isCartSummaryVisible = computed<boolean>(() => ![CartPhase.DETAIL, CartPhase.SUMMARY].includes(phase.value?.id as any))
const canErrorMessageBeShown = computed<boolean>(() => isPhaseActive(CartPhase.DELIVERY))

const errorMessage = ref<string | null>(null)

const ecoMail = useEcoMail()

const { t } = useI18n()

const { notifyError } = useNotifications()

async function submitCartOrder() {
    errorMessage.value = null

    try {
        await submitOrder({
            onSuccess: async () => {
                await navigateTo(localePath({ name: 'cart-summary' }))
            },
        })
    } catch (e) {
        if (e instanceof ApiResponseError) {
            errorMessage.value = e.getErrorMessage()
        }

        notifyError(t('cart.error.order_submit'))

        console.error(e)
    }

    // purposely not awaiting
    if (cart.value?.email) ecoMail.subscribe(EcoMailTag.ORDER, cart.value.email)
}

// fetch cart items
await fetchItems()

</script>

<style lang="scss" scoped>

.sim-l-cart {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto 1fr;
    gap: 2rem 1rem;
}

//.sim-l-cart--fw {
//    grid-template-columns: 1fr;
//}

.sim-l-cart--fw .sim-l-cart__button {
    width: initial;
}

</style>
