import type { PhaseManagerCartPhase } from '@core-theme/types/cart'

/*
  This middleware is being checked in the cart store (Phase Manager) to determine whether we're on
  a cart route or not - so that we only redirect the customer on forced phase change when they're
  in cart and not elsewhere on the page.
  Update the middleware name there when renaming this / refactor it later.
 */

export default defineNuxtRouteMiddleware(async (to, from) => {
    // skip middleware on initial client load
    const nuxtApp = useNuxtApp()
    if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return

    const { switchTo, canSwitchTo, phases, phase, fetchCartIfNotLoaded } = useCart()
    const localePath = useLocalePath()

    if (import.meta.server) {
        await fetchCartIfNotLoaded()
    }

    const newPhase = phases.value.find((phase: PhaseManagerCartPhase) => localePath({ name: phase.routeName }) === to.path)
    if (!newPhase || !canSwitchTo(newPhase)) {
        return navigateTo(localePath({ name: phase.value?.routeName }), { redirectCode: 307 })
    }

    // TODO: refactor later
    const wasCartRoute = from.matched.some(route => route.meta.middleware === 'cart')

    // switch to the new phase right away if on the server or not on a cart route
    if (import.meta.server || !wasCartRoute) {
        switchTo(newPhase)
    } else {
        // in case of a cart route, we want to change the phase only after the page
        // async data has been resolved & the new page mounted
        useOnPageLoaded(() => {
            switchTo(newPhase)
        })
    }
})
