import { useRuntimeConfig } from '#imports'
import type { PublicRuntimeConfig } from '@nuxt/schema'
import type { H3Event } from 'h3'
import type { RuntimeConfig } from 'nuxt/schema'

export function useConfig(event?: H3Event): PublicRuntimeConfig['composableApi'] {
    if (event) return useRuntimeConfig(event).public.composableApi
    return useRuntimeConfig().public.composableApi
}

export function usePrivateConfig(event?: H3Event): Partial<RuntimeConfig['composableApi']> {
    if (!import.meta.server) return {}
    if (event) return useRuntimeConfig(event).composableApi
    return useRuntimeConfig().composableApi
}

