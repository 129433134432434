import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [AttachmentModel.ATTR_ID]: number
    [AttachmentModel.ATTR_NAME]: string
    [AttachmentModel.ATTR_MIME_TYPE]: string
    [AttachmentModel.ATTR_EXTENSION]: string
    [AttachmentModel.ATTR_SIZE]: number
    [AttachmentModel.ATTR_DOWNLOAD_URL]: string | null
    [AttachmentModel.ATTR_STATUS]: string
}

export class AttachmentModel extends ApiModel<Attributes> {
    static key = 'AttachmentModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_MIME_TYPE = 'mime_type'
    static readonly ATTR_EXTENSION = 'extension'
    static readonly ATTR_SIZE = 'size'
    static readonly ATTR_DOWNLOAD_URL = 'download_url'
    static readonly ATTR_STATUS = 'status'

    get id() {
        return this._getAttribute(AttachmentModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(AttachmentModel.ATTR_NAME)
    }

    get mimeType() {
        return this._getAttribute(AttachmentModel.ATTR_MIME_TYPE)
    }

    get extension() {
        return this._getAttribute(AttachmentModel.ATTR_EXTENSION)
    }

    get size() {
        return this._getAttribute(AttachmentModel.ATTR_SIZE)
    }

    get downloadUrl() {
        return this._getAttribute(AttachmentModel.ATTR_DOWNLOAD_URL)
    }

    get status() {
        return this._getAttribute(AttachmentModel.ATTR_STATUS)
    }


    // ---------------------------------------------------------------------------------------------------------------------

}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================

