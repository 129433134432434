<script lang="tsx">
import type { SetupContext, SlotsType } from 'vue'
import type { SizeProp } from '../../../../types/components'
import type { FormFieldObject } from '@core-types/form'
import type { HTMLAttributes } from 'vue'

export type SelectOption<T> = {
    label: string
    value: T
    disabled?: boolean
}

export type SelectOptionGroup<T> = {
    label: string
    options: SelectOption<T>[]
    disabled?: boolean
}

export type SelectOptions<T> = (SelectOption<T> | SelectOptionGroup<T> | string)[]

export type BaseUiSelectProps<T, Colors extends string, Variants extends string, Sizes extends string> = {
    color?: Colors
    variant?: Variants
    size?: Sizes

    /**
     * The placeholder text for the input.
     */
    placeholder?: string
    /**
     * The autocomplete attribute for the input.
     */
    autocomplete?: FormAutocomplete
    /**
     * The options for the select input.
     */
    options: SelectOptions<T> | T[]
    /**
     * The getter of the value to be used as a label.
     */
    labelGetter?: Getter<T>
    /**
     * The getter of the value to be used as a value.
     */
    valueGetter?: Getter<T>

    /**
     * Whether to adjust the padding of the input based on the presence of the leading and trailing slots.
     * If set to `static`, the padding will be the same regardless of the presence of the slots.
     * If set to `dynamic`, the padding will be adjusted based on the presence of the slots to use the vertical padding
     * on the horizontal sides as well for the sides with a slot.
     * @default 'dynamic'
     */
    paddingBehavior?: 'static' | 'dynamic'
    /**
     * Whether to use equal padding on all sides of the input.
     */
    square?: boolean

    /**
     * TODO: document
     */
    autoselect?: boolean | 'submit'
    inline?: boolean

    /**
     * Class to be applied to the leading slot wrapper
     */
    leadingClass?: HTMLAttributes['class']

    /**
     * Class to be applied to the trailing slot wrapper
     */
    trailingClass?: HTMLAttributes['class']
} & BaseFormElementProps<SelectOption<T> | T>

export type BaseUiSelectSlots<T> = {
    default: {}
    leading: {
        data: T
    }
    trailing: {
        data: T
    }
    dropdownIcon: {}
}

type ToSlotFunction<T extends Record<string, Record<string, any>>> = Partial<{
    [K in keyof T]: (data: T[K]) => any
}>

type BaseUiSelectEmits<T> = {
    'update:modelValue': (value: SelectOptions<T> | T | null) => true,
    'update:form': (value: FormFieldObject<SelectOptions<T> | T | null>) => true,
}

type ComponentOptions = {}

export function defineComponentBaseUiSelect<
    Colors extends string,
    Variants extends string = '',
    Sizes extends string = SizeProp,
>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiSelectProps<any, Colors, Variants, Sizes>, BaseUiSelectSlots<any>>) {
    return defineComponent(
        // @ts-ignore
        <T extends string | number>(props: BaseUiSelectProps<T, Colors, Variants, Sizes>, ctx: SetupContext<BaseUiSelectEmits<T>, SlotsType<ToSlotFunction<BaseUiSelectSlots<T>>>>) => {

            const { injected } = useCoreUiFormProvide()
            const { injected: baseUiElementGroupInjected } = useBaseUiElementGroupProvide()

            const isDisabled = computed(() => props.disabled || props.loading)
            const isLeadingSlotRendered = computed<boolean>(() => ctx.slots.leading !== undefined || !!options?.slots?.leading)
            const isTrailingSlotRendered = computed<boolean>(() => !!(ctx.slots.trailing !== undefined || options?.slots?.trailing || ctx.slots.dropdownIcon !== undefined || options?.slots?.dropdownIcon))

            // TODO: REFACTOR
            // Save all options in a map for easier access by value
            const normalizedOptions = computed(() => {
                const map = new Map<unknown, SelectOption<T> | string | T>()
                for (const option of (props.options ?? [])) {
                    // if a getter is specified, it has priority
                    if (props.valueGetter) {
                        // @ts-expect-error
                        const value = getValueByGetter(option, props.valueGetter)
                        if (!value) {
                            errorLog('[BaseUiSelect]: The value getter returned a null value for the option:', option)
                            continue
                        }
                        map.set(value, option as T)
                        continue
                    }


                    if (isOptionGroup(option)) {
                        for (const opt of option.options) {
                            map.set(opt.value as string, opt)
                        }
                    } else {
                        map.set((isOptionObject(option) ? option.value : option) as string, option)
                    }
                }
                return map
            })

            function isOptionGroup(option: any): option is SelectOptionGroup<T> {
                return typeof option === 'object' && 'options' in option && 'label' in option
            }

            function isOptionObject(option: any): option is SelectOption<T> {
                return typeof option === 'object' && 'value' in option && 'label' in option
            }


            const inputValue = computed<SelectOptions<T> | T | null | undefined>({
                get() {
                    return props.modelValue as SelectOptions<T> | T | null | undefined
                },
                set(val) {
                    ctx.emit('update:modelValue', val ?? null)
                },
            })

            const formInputValue = computed<FormFieldObject<SelectOptions<T> | T | null> | undefined>({
                get() {
                    return props.form as FormFieldObject<SelectOptions<T> | T | null> | undefined
                },
                set(val) {
                    ctx.emit('update:form', val!)
                },
            })

            const internalValue = computed<SelectOptions<T> | T | null>({
                get() {
                    // make normal `v-model` have higher priority
                    if (inputValue.value !== undefined) return inputValue.value
                    // otherwise use the form input value binding
                    if (formInputValue.value === undefined) errorLog('[BaseUiSelect]: No v-model value provided', ...[getCurrentInstance()?.vnode.el].filter(Boolean))
                    return formInputValue.value?.__v ?? null
                },
                set(value) {
                    if (inputValue.value !== undefined) {
                        inputValue.value = value
                        // do not set form input value if normal `v-model` is used
                        // this is needed to prevent a bug, but I can't remember which one :(
                        return
                    }
                    if (formInputValue.value === undefined) return
                    formInputValue.value.__v = value
                },
            })

            const realInternalValue = computed(() => internalValue.value ? normalizedOptions.value.get(internalValue.value) : null)

            const errorMessage = computed<string | null>(() => {
                if (!formInputValue.value || !injected.formErrors) return null
                return (injected.formErrors.value[formInputValue.value.__f] ?? null) as string | null
            })

            function handleInputChange() {
                if (!injected.bus || !formInputValue.value) return

                injected.bus.emit({
                    type: 'change',
                    __f: formInputValue.value.__f,
                })
            }

            function handleInputBlur() {
                if (!injected.bus || !formInputValue.value) return

                injected.bus.emit({
                    type: 'blur',
                    __f: formInputValue.value.__f,
                })
            }

            function handleFormErrorReset() {
                if (!injected.resetFormError || !formInputValue.value) return
                injected.resetFormError(formInputValue.value.__f)
            }

            const isRequired = computed<boolean>(() => {
                return props.required ?? formInputValue.value?.__r ?? false
            })

            const isInvalid = computed<boolean | undefined>(() => {
                return props.ariaInvalid ?? errorMessage.value ? true : undefined
            })

            // TODO: refactor
            // TODO: add prop not to auto-select the first option
            // Automatic selection of the first option if no value is selected
            if (internalValue.value === null && normalizedOptions.value.size > 0 && !props.placeholder && props.autoselect) {
                const initialValue = internalValue.value

                const firstOption = normalizedOptions.value.values().next().value
                if (props.valueGetter) {
                    // TODO: fix types
                    internalValue.value = getValueByGetter(firstOption as any, props.valueGetter as any)
                } else if (isOptionObject(firstOption)) {
                    internalValue.value = firstOption.value
                } else {
                    internalValue.value = firstOption as T
                }

                if (props.autoselect === 'submit') {
                    onMounted(async () => {
                        await injected.registeredBusPromise
                        if (initialValue !== internalValue.value && injected.bus && formInputValue.value) {
                            injected.bus.emit({
                                type: '_set-val',
                                __f: formInputValue.value.__f,
                            })
                        }
                    })
                }
            }

            function renderValueOrPlaceholder() {
                if (internalValue.value === null) {
                    if (props.placeholder) {
                        return <span class="sim-select__placeholder">
                            {props.placeholder}
                        </span>
                    } else {
                        return <span style="visibility: hidden;">
                            -
                        </span>
                    }
                } else {
                    if (props.labelGetter) {
                        // TODO: fix types
                        return getValueByGetter(realInternalValue.value as any, props.labelGetter as any)
                    }

                    const currentValue = normalizedOptions.value.get(internalValue.value)
                    return typeof currentValue === 'string'
                        ? currentValue
                        : isOptionObject(currentValue)
                            ? currentValue.label ?? null
                            : currentValue
                }
            }

            const describedBy = computed<string | undefined>(() => {
                if (!props.descriptionId) return
                return Array.isArray(props.descriptionId) ? props.descriptionId.join(' ') : props.descriptionId
            })

            return () => (
                <div class={['sim-select', baseUiElementGroupInjected?.classes.value, {
                    'sim-select--disabled': isDisabled.value,
                    'sim-select--loading': props.loading,
                    'sim-select--error': isInvalid.value,
                    'sim-select--inline': props.inline,
                    [`c-${props.color}`]: props.color,
                    [`v-${props.variant}`]: props.variant,
                    [`s-${props.size}`]: props.size,
                    'sim-select--leading': isLeadingSlotRendered.value && props.paddingBehavior === 'dynamic',
                    'sim-select--trailing': isTrailingSlotRendered.value && props.paddingBehavior === 'dynamic',
                    'sim-select--square': props.square,
                }]}>
                    {isLeadingSlotRendered.value && (
                        <div class={['sim-select__sides', props.leadingClass]} aria-hidden="true">
                            {renderSlot(ctx.slots.leading, options?.slots?.leading, {
                                // @ts-ignore
                                data: realInternalValue.value,
                            })}
                        </div>
                    )}

                    <div class="sim-select__text" aria-hidden="true">
                        {renderValueOrPlaceholder()}
                    </div>

                    <select
                        id={props.id}
                        v-model={internalValue.value}
                        class="sim-select__el"
                        disabled={isDisabled.value}
                        required={isRequired.value}
                        aria-describedby={describedBy.value}
                        aria-label={props.ariaLabel}
                        aria-invalid={isInvalid.value}
                        autocomplete={props.autocomplete}
                        onInput={handleFormErrorReset}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    >
                        { /* if there is no item selected, show the placeholder */ internalValue.value === null && props.placeholder && (
                            <option value="" selected disabled>
                                {props.placeholder}
                            </option>
                        )}

                        {(props.options ?? []).map((option, index) => {
                            if (isOptionGroup(option)) {
                                return (
                                    <optgroup
                                        key={`og${index}`}
                                        label={option.label}
                                        disabled={option.disabled}
                                    >
                                        {option.options.map((opt, i) => (
                                            <option
                                                key={`o${index}-${i}`}
                                                value={opt.value}
                                                disabled={opt.disabled}
                                            >
                                                {opt.label}
                                            </option>
                                        ))}
                                    </optgroup>
                                )
                            } else {
                                return (
                                    <option
                                        key={`o${index}`}
                                        value={isOptionObject(option)
                                            ? option.value
                                            : props.valueGetter
                                                ? getValueByGetter(option as any, props.valueGetter as any)
                                                : option
                                        }
                                        disabled={isOptionObject(option) ? option.disabled : undefined}
                                    >
                                        {isOptionObject(option)
                                            ? option.label
                                            : props.labelGetter
                                                ? getValueByGetter(option as any, props.labelGetter as any)
                                                : option}
                                    </option>
                                )
                            }
                        })}
                    </select>

                    <div class={['sim-select__sides', props.trailingClass]} aria-hidden="true">
                        {isTrailingSlotRendered.value && renderSlot(ctx.slots.trailing, options?.slots?.trailing, {
                            // @ts-ignore
                            data: realInternalValue.value,
                        })}
                        {renderSlot(ctx.slots.dropdownIcon, options?.slots?.dropdownIcon, {})}
                    </div>


                </div>
            )
        },
        {
            props: {
                modelValue: {
                    type: [String, Number, Boolean, Object],
                    default: options?.props?.modelValue?.default,
                    required: options?.props?.modelValue?.required ?? false,
                },
                modelModifiers: {
                    type: Object,
                    default: options?.props?.modelModifiers?.default,
                    required: options?.props?.modelModifiers?.required ?? false,
                },
                form: {
                    type: Object,
                    default: options?.props?.form?.default,
                    required: options?.props?.form?.required ?? false,
                },
                formModifiers: {
                    type: Object,
                    default: options?.props?.formModifiers?.default,
                    required: options?.props?.formModifiers?.required ?? false,
                },
                disabled: {
                    type: Boolean,
                    default: options?.props?.disabled?.default,
                    required: options?.props?.disabled?.required ?? false,
                },
                loading: {
                    type: Boolean,
                    default: options?.props?.loading?.default,
                    required: options?.props?.loading?.required ?? false,
                },
                required: {
                    type: Boolean,
                    default: options?.props?.required?.default,
                    required: options?.props?.required?.required ?? false,
                },
                id: {
                    type: String,
                    default: options?.props?.id?.default,
                    required: options?.props?.id?.required ?? false,
                },
                descriptionId: {
                    type: [String, Array],
                    default: options?.props?.descriptionId?.default,
                    required: options?.props?.descriptionId?.required ?? false,
                },
                ariaLabel: {
                    type: String,
                    default: options?.props?.ariaLabel?.default,
                    required: options?.props?.ariaLabel?.required ?? false,
                },
                ariaInvalid: {
                    type: Boolean,
                    default: options?.props?.ariaInvalid?.default,
                    required: options?.props?.ariaInvalid?.required ?? false,
                },

                color: {
                    type: String,
                    default: options?.props?.color?.default,
                    required: options?.props?.color?.required ?? false,
                },
                variant: {
                    type: String,
                    default: options?.props?.variant?.default,
                    required: options?.props?.variant?.required ?? false,
                },
                size: {
                    type: String,
                    default: options?.props?.size?.default,
                    required: options?.props?.size?.required ?? false,
                },

                placeholder: {
                    type: [String, Number],
                    default: options?.props?.placeholder?.default,
                    required: options?.props?.placeholder?.required ?? false,
                },
                autocomplete: {
                    type: String,
                    default: options?.props?.autocomplete?.default,
                    required: options?.props?.autocomplete?.required ?? false,
                },
                options: {
                    type: Array,
                    default: options?.props?.options?.default,
                    required: options?.props?.options?.required ?? true,
                },
                labelGetter: {
                    type: [Function, String],
                    default: options?.props?.labelGetter?.default,
                    required: options?.props?.labelGetter?.required ?? false,
                },
                valueGetter: {
                    type: [Function, String],
                    default: options?.props?.valueGetter?.default,
                    required: options?.props?.valueGetter?.required ?? false,
                },
                paddingBehavior: {
                    type: String,
                    default: options?.props?.paddingBehavior?.default ?? 'dynamic',
                    required: options?.props?.paddingBehavior?.required ?? false,
                },
                square: {
                    type: Boolean,
                    default: options?.props?.square?.default,
                    required: options?.props?.square?.required ?? false,
                },
                autoselect: {
                    type: [Boolean, String],
                    default: options?.props?.autoselect?.default,
                    required: options?.props?.autoselect?.required ?? false,
                },
                inline: {
                    type: Boolean,
                    default: options?.props?.inline?.default,
                    required: options?.props?.inline?.required ?? false,
                },
                leadingClass: {
                    type: String,
                    default: options?.props?.leadingClass?.default,
                    required: options?.props?.leadingClass?.required ?? false,
                },
                trailingClass: {
                    type: String,
                    default: options?.props?.trailingClass?.default,
                    required: options?.props?.trailingClass?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseUiSelectSlots<any>>,
            emits: {
                'update:modelValue': (value: SelectOptions<any> | any | null) => true,
                'update:form': (value: FormFieldObject<SelectOptions<any> | any | null>) => true,
            } satisfies BaseUiSelectEmits<any>,
        }
    )
}

export default defineComponentBaseUiSelect()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiSelect.scss" as *;
</style>
