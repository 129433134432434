import { ApiModel } from '@composable-api/api.model'
import type { ApiDateTime } from '../types/general-data'
import { BannerModel } from '@simploshop-models/banner.model'

interface Attributes {
    [CategoryModel.ATTR_ID]: number
    [CategoryModel.ATTR_NAME]: string
    [CategoryModel.ATTR_DESCRIPTION]: string
    [CategoryModel.ATTR_PARENT_ID]: number | null
    [CategoryModel.ATTR_IMAGE_ID]: number | null
    [CategoryModel.ATTR_THUMBNAIL_IMAGE_ID]: number | null
    [CategoryModel.ATTR_COLOR]: string | null
    [CategoryModel.ATTR_META_TITLE]: string
    [CategoryModel.ATTR_META_DESCRIPTION]: string
    [CategoryModel.ATTR_SLUG]: string
    [CategoryModel.ATTR_POSITION]: number
    [CategoryModel.ATTR_AVAILABLE_FOR_LANGUAGES]: string[]
    [CategoryModel.ATTR_IS_ACTIVE]: boolean
    [CategoryModel.ATTR_HAS_AUTOMATIC_SLUG]: boolean
    [CategoryModel.ATTR_META_INDEX]: boolean
    [CategoryModel.ATTR_META_FOLLOW]: boolean
    [CategoryModel.ATTR_SHOW_ON_SITEMAP]: boolean
    [CategoryModel.ATTR_CUSTOMER_GROUPS_RESTRICTION]: boolean
    [CategoryModel.ATTR_CREATED_AT]: ApiDateTime
}

interface Embeds {
    /**
     * Embed for category url (for the current language, because we're sending the `Language-Preference` header)
     * The name is `urls` because of backward compatibility (when the header is not sent, the response will contain all urls)
     */
    [CategoryModel.EMBED_URLS]: string
    [CategoryModel.EMBED_PRODUCTS_COUNT]: number
    [CategoryModel.EMBED_RESTRICTED_CUSTOMER_GROUP_IDS]: number[]
    [CategoryModel.EMBED_IMAGE_URL]: string | null
    [CategoryModel.EMBED_THUMBNAIL_IMAGE_URL]: string | null
    [CategoryModel.EMBED_OG_IMAGE_URL]: string | null
    [CategoryModel.EMBED_BANNERS]: BannerModel[]
}

export class CategoryModel<TAttributes = unknown> extends ApiModel<Attributes & TAttributes, Embeds> {
    static key = 'CategoryModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_PARENT_ID = 'parent_id'
    static readonly ATTR_IMAGE_ID = 'image_id'
    static readonly ATTR_THUMBNAIL_IMAGE_ID = 'thumbnail_image_id'
    static readonly ATTR_COLOR = 'color'
    static readonly ATTR_META_TITLE = 'meta_title'
    static readonly ATTR_META_DESCRIPTION = 'meta_description'
    static readonly ATTR_SLUG = 'slug'
    static readonly ATTR_POSITION = 'position'
    static readonly ATTR_AVAILABLE_FOR_LANGUAGES = 'available_for_languages'
    static readonly ATTR_IS_ACTIVE = 'is_active'
    static readonly ATTR_HAS_AUTOMATIC_SLUG = 'has_automatic_slug'
    static readonly ATTR_META_INDEX = 'meta_index'
    static readonly ATTR_META_FOLLOW = 'meta_follow'
    static readonly ATTR_SHOW_ON_SITEMAP = 'show_on_sitemap'
    static readonly ATTR_CUSTOMER_GROUPS_RESTRICTION = 'customer_groups_restriction'
    static readonly ATTR_CREATED_AT = 'created_at'

    static readonly EMBED_URLS = 'urls'
    static readonly EMBED_PRODUCTS_COUNT = 'products_count'
    static readonly EMBED_RESTRICTED_CUSTOMER_GROUP_IDS = 'restricted_customer_group_ids'
    static readonly EMBED_IMAGE_URL = 'image_url'
    static readonly EMBED_THUMBNAIL_IMAGE_URL = 'thumbnail_image_url'
    static readonly EMBED_OG_IMAGE_URL = 'og_image_url'
    static readonly EMBED_BANNERS = 'banners'

    get id() {
        return this._getAttribute(CategoryModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(CategoryModel.ATTR_NAME)
    }

    get description() {
        return this._getAttribute(CategoryModel.ATTR_DESCRIPTION)
    }

    get parentId() {
        return this._getAttribute(CategoryModel.ATTR_PARENT_ID)
    }

    get imageId() {
        return this._getAttribute(CategoryModel.ATTR_IMAGE_ID)
    }

    get thumbnailImageId() {
        return this._getAttribute(CategoryModel.ATTR_THUMBNAIL_IMAGE_ID)
    }

    get color() {
        return this._getAttribute(CategoryModel.ATTR_COLOR)
    }

    get metaTitle() {
        return this._getAttribute(CategoryModel.ATTR_META_TITLE)
    }

    get metaDescription() {
        return this._getAttribute(CategoryModel.ATTR_META_DESCRIPTION)
    }

    get slug() {
        return this._getAttribute(CategoryModel.ATTR_SLUG)
    }

    get position() {
        return this._getAttribute(CategoryModel.ATTR_POSITION)
    }

    get availableForLanguages() {
        return this._getAttribute(CategoryModel.ATTR_AVAILABLE_FOR_LANGUAGES)
    }

    get isActive() {
        return this._getAttribute(CategoryModel.ATTR_IS_ACTIVE)
    }

    get hasAutomaticSlug() {
        return this._getAttribute(CategoryModel.ATTR_HAS_AUTOMATIC_SLUG)
    }

    get metaIndex() {
        return this._getAttribute(CategoryModel.ATTR_META_INDEX)
    }

    get metaFollow() {
        return this._getAttribute(CategoryModel.ATTR_META_FOLLOW)
    }

    get showOnSitemap() {
        return this._getAttribute(CategoryModel.ATTR_SHOW_ON_SITEMAP)
    }

    get customerGroupsRestriction() {
        return this._getAttribute(CategoryModel.ATTR_CUSTOMER_GROUPS_RESTRICTION)
    }

    get createdAt() {
        return this._getAttribute(CategoryModel.ATTR_CREATED_AT)
    }

    get urls() {
        return this._getEmbed(CategoryModel.EMBED_URLS)
    }

    get productsCount() {
        return this._getEmbed(CategoryModel.EMBED_PRODUCTS_COUNT)
    }

    get restrictedCustomerGroupIds() {
        return this._getEmbed(CategoryModel.EMBED_RESTRICTED_CUSTOMER_GROUP_IDS)
    }

    get imageUrl() {
        return this._getEmbed(CategoryModel.EMBED_IMAGE_URL)
    }

    get thumbnailImageUrl() {
        return this._getEmbed(CategoryModel.EMBED_THUMBNAIL_IMAGE_URL)
    }

    get ogImageUrl() {
        return this._getEmbed(CategoryModel.EMBED_OG_IMAGE_URL)
    }

    get banners() {
        return this._getEmbed(CategoryModel.EMBED_BANNERS, BannerModel)
    }

    getUrl() {
        return this.urls
    }
}
