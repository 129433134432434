import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [ProductVideoModel.ATTR_ID]: number
    [ProductVideoModel.ATTR_DESCRIPTION]: string
    [ProductVideoModel.ATTR_POSITION]: number | null
    [ProductVideoModel.ATTR_PRODUCT_ID]: number
    [ProductVideoModel.ATTR_SOURCE_TYPE]: string
    [ProductVideoModel.ATTR_EMBED_URL]: string | null
    [ProductVideoModel.ATTR_THUMBNAIL_URL]: string | null
    [ProductVideoModel.ATTR_VIDEO_ID]: string | null
    [ProductVideoModel.ATTR_URL]: string
}

export class ProductVideoModel extends ApiModel<Attributes> {
    static key = 'ProductVideoModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_PRODUCT_ID = 'product_id'
    static readonly ATTR_POSITION = 'position'
    static readonly ATTR_SOURCE_TYPE = 'source_type'
    static readonly ATTR_EMBED_URL = 'embed_url'
    static readonly ATTR_THUMBNAIL_URL = 'thumbnail_url'
    static readonly ATTR_VIDEO_ID = 'video_id'
    static readonly ATTR_URL = 'url'

    get id() {
        return this._getAttribute(ProductVideoModel.ATTR_ID)
    }

    get description() {
        return this._getAttribute(ProductVideoModel.ATTR_DESCRIPTION)
    }

    get productId() {
        return this._getAttribute(ProductVideoModel.ATTR_PRODUCT_ID)
    }

    get position() {
        return this._getAttribute(ProductVideoModel.ATTR_POSITION)
    }

    get sourceType() {
        return this._getAttribute(ProductVideoModel.ATTR_SOURCE_TYPE)
    }

    get embedUrl() {
        return this._getAttribute(ProductVideoModel.ATTR_EMBED_URL)
    }

    get thumbnailUrl() {
        return this._getAttribute(ProductVideoModel.ATTR_THUMBNAIL_URL)
    }

    get videoId() {
        return this._getAttribute(ProductVideoModel.ATTR_VIDEO_ID)
    }

    get url() {
        return this._getAttribute(ProductVideoModel.ATTR_URL)
    }

    isYouTube() {
        return this.sourceType === 'YouTube'
    }

    getIframeUrl() {
        if (!this.isYouTube()) return null
        if (!this.videoId) return null

        const params = new URLSearchParams()
        params.append('playsinline', '1')

        return `https://www.youtube-nocookie.com/embed/${encodeURIComponent(this.videoId)}?${params.toString()}`
    }
}
