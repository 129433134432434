import { ApiModel } from '@composable-api/api.model'
import { ProductFilterTemplatePlaceholder } from '../enums/product'

export interface ProductFiltersPaginationModelAttributes {
    [ProductFiltersPaginationModel.ATTR_ITEMS_COUNT]: number
    [ProductFiltersPaginationModel.ATTR_MULTI_PAGINATION_START_PAGE]: number | null
    [ProductFiltersPaginationModel.ATTR_PAGE]: number
    [ProductFiltersPaginationModel.ATTR_PAGES]: number
    [ProductFiltersPaginationModel.ATTR_PER_PAGE]: number
    [ProductFiltersPaginationModel.ATTR_PER_PAGE_OPTIONS]: number[]
    [ProductFiltersPaginationModel.ATTR_PAGE_RANGE_URL_TEMPLATE]: string
    [ProductFiltersPaginationModel.ATTR_PAGE_URL_TEMPLATE]: string
    [ProductFiltersPaginationModel.ATTR_PER_PAGE_URL_TEMPLATE]: string
}

interface Embeds {
}

export class ProductFiltersPaginationModel extends ApiModel<ProductFiltersPaginationModelAttributes, Embeds> {
    static key = 'ProductFiltersPaginationModel'

    static readonly ATTR_ITEMS_COUNT = 'items_count'
    static readonly ATTR_MULTI_PAGINATION_START_PAGE = 'multi_pagination_start_page'
    static readonly ATTR_PAGE = 'page'
    static readonly ATTR_PAGES = 'pages'
    static readonly ATTR_PER_PAGE = 'per_page'
    static readonly ATTR_PER_PAGE_OPTIONS = 'per_page_options'
    static readonly ATTR_PAGE_RANGE_URL_TEMPLATE = 'page_range_url_template'
    static readonly ATTR_PAGE_URL_TEMPLATE = 'page_url_template'
    static readonly ATTR_PER_PAGE_URL_TEMPLATE = 'per_page_url_template'

    get itemsCount() {
        return this._getAttribute(ProductFiltersPaginationModel.ATTR_ITEMS_COUNT)
    }

    get multiPaginationStartPage() {
        return this._getAttribute(ProductFiltersPaginationModel.ATTR_MULTI_PAGINATION_START_PAGE)
    }

    get page() {
        return this._getAttribute(ProductFiltersPaginationModel.ATTR_PAGE)
    }

    get pages() {
        return this._getAttribute(ProductFiltersPaginationModel.ATTR_PAGES)
    }

    get perPage() {
        return this._getAttribute(ProductFiltersPaginationModel.ATTR_PER_PAGE)
    }

    get perPageOptions() {
        return this._getAttribute(ProductFiltersPaginationModel.ATTR_PER_PAGE_OPTIONS)
    }

    get pageRangeUrlTemplate() {
        return this._getAttribute(ProductFiltersPaginationModel.ATTR_PAGE_RANGE_URL_TEMPLATE)
    }

    get pageUrlTemplate() {
        return this._getAttribute(ProductFiltersPaginationModel.ATTR_PAGE_URL_TEMPLATE)
    }

    get perPageUrlTemplate() {
        return this._getAttribute(ProductFiltersPaginationModel.ATTR_PER_PAGE_URL_TEMPLATE)
    }

    // --------------------------------------------------

    hasReachedLastPage() {
        if (!this.page || !this.pages) return true
        return this.page >= this.pages
    }

    // --------------------------------------------------

    getPageRangeUrl(from: number, to: number) {
        if (!this.pageRangeUrlTemplate) return null

        return this.pageRangeUrlTemplate
            .replace(ProductFilterTemplatePlaceholder.FROM, from.toString())
            .replace(ProductFilterTemplatePlaceholder.TO, to.toString())
    }

    getNextPageRangeUrl() {
        if (!this.page) return null

        return this.getPageRangeUrl(this.multiPaginationStartPage ?? this.page, this.page + 1)
    }

    getPageUrl(page: number) {
        if (!this.pageUrlTemplate) return null

        return this.pageUrlTemplate
            .replace(ProductFilterTemplatePlaceholder.PAGE, page.toString())
    }

    getPerPageUrl(perPage: number) {
        if (!this.perPageUrlTemplate) return null

        return this.perPageUrlTemplate
            .replace(ProductFilterTemplatePlaceholder.PER_PAGE, perPage.toString())
    }

}
