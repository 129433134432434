import type { Monetary, ApiDateTime } from '../types/general-data'
import { ApiModel } from '@composable-api/api.model'
import { ProductModel } from './product.model'
import { getPriceValue } from '../utils/localization'

interface Attributes {
    [CartItemModel.ATTR_ID]: number
    [CartItemModel.ATTR_NAME]: string | null
    [CartItemModel.ATTR_IMAGE_ID]: number | null
    [CartItemModel.ATTR_PRODUCT_ID]: number
    [CartItemModel.ATTR_PRODUCT_VARIATION_ID]: number | null
    [CartItemModel.ATTR_AMOUNT]: number
    [CartItemModel.ATTR_PARENT_ID]: number | null
    [CartItemModel.ATTR_PAYLOAD]: Payload | null
    [CartItemModel.ATTR_UNIT_PRICE]: Monetary
    [CartItemModel.ATTR_TAXED_UNIT_PRICE]: Monetary
    [CartItemModel.ATTR_ORIGINAL_UNIT_PRICE]: Monetary
    [CartItemModel.ATTR_TAXED_ORIGINAL_UNIT_PRICE]: Monetary
    [CartItemModel.ATTR_TOTAL_PRICE]: Monetary
    [CartItemModel.ATTR_TAXED_TOTAL_PRICE]: Monetary
    [CartItemModel.ATTR_TAX_AMOUNT]: number
    [CartItemModel.ATTR_PRODUCT_URLS]: string | null
    [CartItemModel.ATTR_UPDATE_STOCK]: boolean
    [CartItemModel.ATTR_STOCK_STATE]: number | null
    [CartItemModel.ATTR_WEIGHT]: Weight | null
}

interface Embeds {
    [CartItemModel.EMBED_VARIATION_PROPERTIES_NAMES]?: Record<string, string> | null
    [CartItemModel.EMBED_BRAND]?: Brand
    [CartItemModel.EMBED_DEFAULT_CATEGORY]?: Category
    [CartItemModel.EMBED_PRODUCT_AVAILABILITY]?: ProductAvailability
    [CartItemModel.EMBED_STOCK_STATES]?: StockState[]
    [CartItemModel.EMBED_PRODUCT]?: ProductModel
    [CartItemModel.EMBED_DISCOUNT_PERCENTS]?: number | null
    [CartItemModel.EMBED_PRODUCT_VARIATION]?: ProductModel
    [CartItemModel.EMBED_IMAGE_URL]?: string | null
    [CartItemModel.EMBED_PRODUCT_AMOUNT_UNIT_TRANSLATIONS]?: string | null
    [CartItemModel.EMBED_PRODUCT_ADDITIONAL_DATA]?: ProductAdditionalData
    [CartItemModel.EMBED_EXPORT_PRODUCT_IDS]?: ExportProductIds
}

export class CartItemModel extends ApiModel<Attributes, Embeds> {
    static key = 'CartItemModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_IMAGE_ID = 'image_id'
    static readonly ATTR_PRODUCT_ID = 'product_id'
    static readonly ATTR_PRODUCT_VARIATION_ID = 'product_variation_id'
    static readonly ATTR_AMOUNT = 'amount'
    static readonly ATTR_PARENT_ID = 'parent_id'
    static readonly ATTR_PAYLOAD = 'payload'
    static readonly ATTR_UNIT_PRICE = 'unit_price'
    static readonly ATTR_TAXED_UNIT_PRICE = 'taxed_unit_price'
    static readonly ATTR_ORIGINAL_UNIT_PRICE = 'original_unit_price'
    static readonly ATTR_TAXED_ORIGINAL_UNIT_PRICE = 'taxed_original_unit_price'
    static readonly ATTR_TOTAL_PRICE = 'total_price'
    static readonly ATTR_TAXED_TOTAL_PRICE = 'taxed_total_price'
    static readonly ATTR_TAX_AMOUNT = 'tax_amount'
    static readonly ATTR_PRODUCT_URLS = 'product_urls'
    static readonly ATTR_UPDATE_STOCK = 'update_stock'
    static readonly ATTR_STOCK_STATE = 'stock_state'
    static readonly ATTR_WEIGHT = 'weight'

    static readonly EMBED_VARIATION_PROPERTIES_NAMES = 'variation_properties_names'
    static readonly EMBED_BRAND = 'brand'
    static readonly EMBED_DEFAULT_CATEGORY = 'default_category'
    static readonly EMBED_PRODUCT_AVAILABILITY = 'product_availability'
    static readonly EMBED_STOCK_STATES = 'stock_states'
    static readonly EMBED_PRODUCT = 'product'
    static readonly EMBED_DISCOUNT_PERCENTS = 'discount_percents'
    static readonly EMBED_PRODUCT_VARIATION = 'product_variation'
    static readonly EMBED_IMAGE_URL = 'image_url'
    static readonly EMBED_PRODUCT_AMOUNT_UNIT_TRANSLATIONS = 'product_amount_unit_translations'
    static readonly EMBED_PRODUCT_ADDITIONAL_DATA = 'product_additional_data'
    static readonly EMBED_EXPORT_PRODUCT_IDS = 'export_product_ids'

    get id() {
        return this._getAttribute(CartItemModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(CartItemModel.ATTR_NAME)
    }

    get imageId() {
        return this._getAttribute(CartItemModel.ATTR_IMAGE_ID)
    }

    get productId() {
        return this._getAttribute(CartItemModel.ATTR_PRODUCT_ID)
    }

    get productVariationId() {
        return this._getAttribute(CartItemModel.ATTR_PRODUCT_VARIATION_ID)
    }

    get amount() {
        return this._getAttribute(CartItemModel.ATTR_AMOUNT)
    }

    get parentId() {
        return this._getAttribute(CartItemModel.ATTR_PARENT_ID)
    }

    get payload() {
        return this._getAttribute(CartItemModel.ATTR_PAYLOAD)
    }

    get unitPrice() {
        return this._getAttribute(CartItemModel.ATTR_UNIT_PRICE)
    }

    get taxedUnitPrice() {
        return this._getAttribute(CartItemModel.ATTR_TAXED_UNIT_PRICE)
    }

    get originalUnitPrice() {
        return this._getAttribute(CartItemModel.ATTR_ORIGINAL_UNIT_PRICE)
    }

    get taxedOriginalUnitPrice() {
        return this._getAttribute(CartItemModel.ATTR_TAXED_ORIGINAL_UNIT_PRICE)
    }

    get totalPrice() {
        return this._getAttribute(CartItemModel.ATTR_TOTAL_PRICE)
    }

    get taxedTotalPrice() {
        return this._getAttribute(CartItemModel.ATTR_TAXED_TOTAL_PRICE)
    }

    get taxAmount() {
        return this._getAttribute(CartItemModel.ATTR_TAX_AMOUNT)
    }

    get productUrls() {
        return this._getAttribute(CartItemModel.ATTR_PRODUCT_URLS)
    }

    get updateStock() {
        return this._getAttribute(CartItemModel.ATTR_UPDATE_STOCK)
    }

    get stockState() {
        return this._getAttribute(CartItemModel.ATTR_STOCK_STATE)
    }

    get weight() {
        return this._getAttribute(CartItemModel.ATTR_WEIGHT)
    }

    private get variationPropertiesNames() {
        return this._getEmbed(CartItemModel.EMBED_VARIATION_PROPERTIES_NAMES)
    }

    get brand() {
        return this._getEmbed(CartItemModel.EMBED_BRAND)
    }

    get defaultCategory() {
        return this._getEmbed(CartItemModel.EMBED_DEFAULT_CATEGORY)
    }

    get productAvailability() {
        return this._getEmbed(CartItemModel.EMBED_PRODUCT_AVAILABILITY)
    }

    get stockStates() {
        return this._getEmbed(CartItemModel.EMBED_STOCK_STATES)
    }

    get product() {
        return this._getEmbed(CartItemModel.EMBED_PRODUCT)
    }

    get discountPercents() {
        return this._getEmbed(CartItemModel.EMBED_DISCOUNT_PERCENTS)
    }

    get productVariation() {
        return this._getEmbed(CartItemModel.EMBED_PRODUCT_VARIATION)
    }

    get imageUrl() {
        return this._getEmbed(CartItemModel.EMBED_IMAGE_URL)
    }

    get productAmountUnitTranslations() {
        return this._getEmbed(CartItemModel.EMBED_PRODUCT_AMOUNT_UNIT_TRANSLATIONS)
    }

    get productAdditionalData() {
        return this._getEmbed(CartItemModel.EMBED_PRODUCT_ADDITIONAL_DATA)
    }

    get exportProductIds() {
        return this._getEmbed(CartItemModel.EMBED_EXPORT_PRODUCT_IDS)
    }

    // ---------------------------------------------------------------------------------------------------------------------

    isDiscounted() {
        return getPriceValue(this.taxedOriginalUnitPrice)! > getPriceValue(this.taxedUnitPrice)!
    }

    hasVariationProperties() {
        return this.productVariationId && this.variationPropertiesNames
    }

    isAvailable() {
        return this.productAvailability?.can_purchase ?? false
    }

    canBePurchased() {
        const hasStock = this.stockState === null || this.stockState > 0
        return this.isAvailable() && hasStock
    }

    getFormattedStockCount() {
        return `${this.stockState} ${this.productAmountUnitTranslations}`
    }

    /**
     * Get an objet with the variation **property names** as keys and their **selected attribute names** as values.
     * @example
     * {
     *     'Color': 'red',
     * }
     */
    getValuesOfVariationProperties() {
        return this.variationPropertiesNames ?? {}
    }

}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================

interface Weight {
    unit: string
    value: number
}

interface Payload {
    packaging?: string
}

interface ProductAvailability {
    id: number
    name: string | null
    slug: string | null
    note: string | null
    // whether the products with this availability can be purchased
    can_purchase: boolean
    // whether to show products with this availability in the catalog
    in_catalog: boolean
    // whether to set this availability when the product sells out
    for_empty_stock: boolean
    // whether to set this availability when a sold out product is refilled
    for_refilled_stock: boolean
    // whether to show stock states for products with this availability
    show_stock_states: boolean
    has_automatic_slug: boolean
}

interface Brand {
    id: number
    name: string | null
    description: string | null
    image_id: number | null
    meta_title: string | null
    meta_description: string | null
    slug: string | null
    is_active: boolean
    has_automatic_slug: boolean
    meta_index: boolean
    meta_follow: boolean

    show_on_sitemap: boolean
    created_at: ApiDateTime
}

interface Category {
    id: number
    name: string | null
    description: string | null
    parent_id: number | null
    image_id: number | null
    thumbnail_image_id: number | null
    meta_title: string | null
    meta_description: string | null
    slug: string | null
    position: number
    available_for_languages: string[]
    is_active: boolean
    has_automatic_slug: boolean
    meta_index: boolean
    meta_follow: boolean
    show_on_sitemap: boolean
    customer_groups_restriction: boolean
    created_ad: ApiDateTime
}

interface StockState {
    stock_id: number
    state: number
}

interface ProductAdditionalData {} // TODO: add props

interface ExportProductIds {
    'zbozi'?: string
    'google-merchant'?: string
    'heureka'?: string
    'glami'?: string
}
