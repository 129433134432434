<template>
    <FilterBlock :title="filter.label"
                 collapsible
                 :expanded="expanded"
    >
        <a v-for="filterItem in colorSwatches"
           :key="filterItem.id"
           :href="filterItem.url"
           class="hidden"
        >
            {{ filterItem.label }}
        </a>

        <UiColorSwatchSelectorCircular
            v-model="selectedColors"
            :values="colorSwatches"
            multiple
        />

    </FilterBlock>
</template>

<script lang="ts" setup>
import type { ColorSwatch } from '@sim-api-types/components'

const {
    filter,
    expanded,
} = defineProps<{
    filter: InstanceType<typeof ProductFilterModel>
    expanded?: boolean
}>()

const emit = defineEmits<{
    change: [url: string]
}>()

const colorSwatches = computed<(ColorSwatch & { id: string, url: string })[]>(() => {
    if (!filter.items) return []

    return filter.items
        .filter(item => item.shouldBeVisible())
        .map(item => ({
            id: item.id!,
            value: item.value ?? '',
            label: item.label ?? '',
            url: item.url!,
            imageUrl: item.getThumbnailUrl() ?? undefined,
        }))
})

const selectedColors = computed<string[]>({
    get() {
        return filter.items?.filter(item => item.isSelected).map(item => item.value!) ?? []
    },
    // TODO: refactor?
    set(value) {
        if (selectedColors.value.length > value.length) {
            const removedColor = selectedColors.value.find(color => !value.includes(color))
            if (!removedColor) return

            const removedFilter = filter.items?.find(item => item.value === removedColor)
            if (!removedFilter) return

            emit('change', removedFilter.url!)
        } else {
            const addedColor = value.find(color => !selectedColors.value.includes(color))
            if (!addedColor) return

            const addedFilter = filter.items?.find(item => item.value === addedColor)
            if (!addedFilter) return

            emit('change', addedFilter.url!)
        }
    },
})

</script>
