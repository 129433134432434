import { ApiModel } from '@composable-api/api.model'
import type { NuxtLinkProps } from '#app'
import type { Locale } from '../enums/i18n'
import { getLocaleKey } from '../utils/localization'
import type { ApiLangID } from '../types/general-data'

interface Attributes {
    [PageModel.ATTR_BREADCRUMBS]?: {
        text: string
        url: string | null
        model: number | null
    }[]
    [PageModel.ATTR_HEADERS]?: {
        [key: string]: string | string[]
    }
    [PageModel.ATTR_KIND]: 'model' | 'homepage' | 'blog-list' | 'redirect'
    [PageModel.ATTR_MODEL]?: {
        type: number
        id: number
    }
    [PageModel.ATTR_STRUCTURED_DATA]?: {
        [key: string]: any
    }
    [PageModel.ATTR_TITLE]?: string
    [PageModel.ATTR_FULL_TITLE]?: string
    [PageModel.ATTR_META_TAGS]?: ({
        name: string
        content: string
    } | {
        property: string
        content: string
    })[]
    [PageModel.ATTR_LINKS]?: {
        rel: string
        href: string
    }[]
    [PageModel.ATTR_URLS]?: Partial<Record<ApiLangID, string>>

    // Redirect attributes
    [PageModel.ATTR_DESTINATION]?: string
    [PageModel.ATTR_STATUS_CODE]?: number
}

interface Embeds {
}

export class PageModel extends ApiModel<Attributes, Embeds> {
    static key = 'PageModel'

    static readonly ATTR_BREADCRUMBS = 'breadcrumbs'
    static readonly ATTR_HEADERS = 'headers'
    static readonly ATTR_KIND = 'kind'
    static readonly ATTR_MODEL = 'model'
    static readonly ATTR_STRUCTURED_DATA = 'structured_data'
    static readonly ATTR_TITLE = 'title'
    static readonly ATTR_FULL_TITLE = 'full_title'
    static readonly ATTR_META_TAGS = 'meta_tags'
    static readonly ATTR_LINKS = 'links'
    static readonly ATTR_URLS = 'urls'

    // Redirect attributes (only these)
    static readonly ATTR_DESTINATION = 'destination'
    static readonly ATTR_STATUS_CODE = 'status_code'

    get breadcrumbs() {
        return this._getAttribute(PageModel.ATTR_BREADCRUMBS)
    }

    get headers() {
        return this._getAttribute(PageModel.ATTR_HEADERS)
    }

    get kind() {
        return this._getAttribute(PageModel.ATTR_KIND)
    }

    get model() {
        return this._getAttribute(PageModel.ATTR_MODEL)
    }

    get structuredData() {
        return this._getAttribute(PageModel.ATTR_STRUCTURED_DATA)
    }

    get title() {
        return this._getAttribute(PageModel.ATTR_TITLE)
    }

    get fullTitle() {
        return this._getAttribute(PageModel.ATTR_FULL_TITLE)
    }

    get metaTags() {
        return this._getAttribute(PageModel.ATTR_META_TAGS)
    }

    get links() {
        return this._getAttribute(PageModel.ATTR_LINKS)
    }

    /**
     * The URLs for the page in different languages.
     */
    get urls() {
        return this._getAttribute(PageModel.ATTR_URLS)
    }

    // Redirect attributes
    get destination() {
        return this._getAttribute(PageModel.ATTR_DESTINATION)
    }

    get statusCode() {
        return this._getAttribute(PageModel.ATTR_STATUS_CODE)
    }

    // ----------------------

    isType(type: PageTypeName) {
        const modelMaps = {
            product: 1,
            article: 12,
            category: 16,
            staticPage: 18,
            articleCategory: 19,
        }

        const wantedModelID: number | undefined = modelMaps[type as keyof typeof modelMaps]

        return (this.kind === 'model' && this.model?.type === wantedModelID) ||
        (this.kind === type)
    }

    getPageType(): PageTypeName | null {
        if (this.kind === 'model') {
            return MODEL_TYPE_MAP[this.model?.type as keyof typeof MODEL_TYPE_MAP] ?? null
        }

        if (!this.kind) return null

        return KIND_MAP[this.kind]
    }

    getBreadcrumbs(): { label: string, to: NuxtLinkProps['to'] | null }[] {
        if (!this.breadcrumbs) {
            return []
        }

        return this.breadcrumbs.map(breadcrumb => ({
            label: breadcrumb.text,
            to: breadcrumb.url,
        }))
    }

    isLinkInBreadcrumbs(link: string | null | undefined): boolean {
        if (!link) return false

        return this.breadcrumbs?.some(breadcrumb => breadcrumb.url === link) ?? false
    }

    /**
     * Get a relative url for the current page for the specified locale.
     * @param lang the locale to get the url for
     */
    getUrlForLang(lang: Locale | string) {
        return this.urls?.[getLocaleKey(lang as Locale)]
    }

}

const MODEL_TYPE_MAP = {
    9: 'product',
    12: 'article',
    15: 'brand',
    16: 'category',
    18: 'static-page',
    19: 'article-category',
} as const

const KIND_MAP = {
    'homepage': 'homepage',
    'blog-list': 'blog-list',
    'redirect': null,
} as const satisfies Omit<Record<Attributes['kind'], unknown>, 'model'>

export type PageTypeName = NonNullable<typeof MODEL_TYPE_MAP[keyof typeof MODEL_TYPE_MAP] | typeof KIND_MAP[keyof typeof KIND_MAP]>
