import { ApiModel } from '@composable-api/api.model'
import { ProductModel } from './product.model'
import type { ProductFilterModelAttributes } from './product-filter.model'
import { ProductFilterModel } from './product-filter.model'
import type { ProductFiltersPaginationModelAttributes } from '../models/product-filters-pagination.model'
import { ProductFiltersPaginationModel } from '../models/product-filters-pagination.model'

interface Attributes {
    [ProductFiltersResponseModel.ATTR_FILTERS]: ProductFilterModelAttributes[]
    [ProductFiltersResponseModel.ATTR_ITEMS]: ProductModel[]
    [ProductFiltersResponseModel.ATTR_PAGINATION_DATA]: ProductFiltersPaginationModelAttributes
    [ProductFiltersResponseModel.ATTR_REQUEST_URI]: string
    [ProductFiltersResponseModel.ATTR_SORT_OPTIONS]: SortOption[]
}

export class ProductFiltersResponseModel extends ApiModel<Attributes> {
    static key = 'ProductFiltersResponseModel'

    static readonly ATTR_FILTERS = 'filters'
    static readonly ATTR_ITEMS = 'items'
    static readonly ATTR_PAGINATION_DATA = 'pagination_data'
    static readonly ATTR_REQUEST_URI = 'request_uri'
    static readonly ATTR_SORT_OPTIONS = 'sort_options'

    get filters() {
        return this._getAttribute(ProductFiltersResponseModel.ATTR_FILTERS, ProductFilterModel)
    }

    get items() {
        return this._getAttribute(ProductFiltersResponseModel.ATTR_ITEMS, ProductModel)
    }

    get paginationData() {
        return this._getAttribute(ProductFiltersResponseModel.ATTR_PAGINATION_DATA, ProductFiltersPaginationModel)
    }

    get requestUri() {
        return this._getAttribute(ProductFiltersResponseModel.ATTR_REQUEST_URI)
    }

    get sortOptions() {
        return this._getAttribute(ProductFiltersResponseModel.ATTR_SORT_OPTIONS)
    }

    getProducts() {
        return this.items ?? []
    }

    isFilterPanelVisible() {
        return this.getFilterBlocks().length > 0
    }

    /**
     * Get the filter blocks that should be displayed.
     * Doesn't include blocks that don't have any filters available. (e.g. empty blocks because of current configuration)
     */
    getFilterBlocks() {
        return this.filters?.filter(block => block.isDisplayed) ?? []
    }

    getSelectedFilters(): SelectedFilter[] {
        return this.getFilterBlocks().map((block) => {
            // For range filters, we need to return a selected filter with the full range url
            if (block.isRangeActive()) {
                return {
                    id: block.id!,
                    label: block.additionalProps!.current_min + ' - ' + block.additionalProps!.current_max,
                    type: block.type!,
                    url: block.getRangeUrl()!,
                }
            // For other filters, we return the ones that are selected (they already have the correct url to disable them)
            } else if (block.items) {
                return block.items.filter(item => item.isSelected).map(item => ({
                    id: item.id!,
                    label: item.label!,
                    type: block.type!,
                    url: item.url!,
                }))
            }

            return []
        }).flat()
    }
}

export interface SelectedFilter {
    id: string
    label: string
    type?: string
    url: string
}

export interface SortOption {
    asc_url: string
    desc_url: string
    is_selected: boolean
    label: string
    selected_direction: 'asc' | 'desc'
    slug: string
}
