import { ApiModel } from '@composable-api/api.model'
import { FormattedCurrencyModel } from '../models/custom/formatted-currency.model'
import type { FormattedCurrency } from '../types/general-data'

interface Attributes {
    [CartPaymentMethodModel.ATTR_ID]: number
    [CartPaymentMethodModel.ATTR_NAME]: string | null
    [CartPaymentMethodModel.ATTR_PRICE]: FormattedCurrency
    [CartPaymentMethodModel.ATTR_TAXED_PRICE]: FormattedCurrency
    [CartPaymentMethodModel.ATTR_CAN_USE]: boolean
    [CartPaymentMethodModel.ATTR_PAYMENT_SERVICE]: number | null
    [CartPaymentMethodModel.ATTR_POSITION]: number | null
}

interface Embeds {
    [CartPaymentMethodModel.EMBED_IMAGE_URL]: string
}

export class CartPaymentMethodModel extends ApiModel<Attributes, Embeds> {
    static key = 'CartPaymentMethodModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_PRICE = 'price'
    static readonly ATTR_TAXED_PRICE = 'taxed_price'
    static readonly ATTR_CAN_USE = 'can_use'
    static readonly ATTR_PAYMENT_SERVICE = 'payment_service'
    static readonly ATTR_POSITION = 'position'

    static readonly EMBED_IMAGE_URL = 'image_url'

    get id() {
        return this._getAttribute(CartPaymentMethodModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(CartPaymentMethodModel.ATTR_NAME)
    }

    get price() {
        return this._getAttribute(CartPaymentMethodModel.ATTR_PRICE, FormattedCurrencyModel)
    }

    get taxedPrice() {
        return this._getAttribute(CartPaymentMethodModel.ATTR_TAXED_PRICE, FormattedCurrencyModel)
    }

    get canUse() {
        return this._getAttribute(CartPaymentMethodModel.ATTR_CAN_USE)
    }

    get paymentService() {
        return this._getAttribute(CartPaymentMethodModel.ATTR_PAYMENT_SERVICE)
    }

    get position() {
        return this._getAttribute(CartPaymentMethodModel.ATTR_POSITION)
    }

    get imageUrl() {
        return this._getEmbed(CartPaymentMethodModel.EMBED_IMAGE_URL)
    }
}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================

