import validate from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_jnr4jg7ta3acnwtd7bgw6cn4di/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/simploshop/shared/core-theme/app/middleware/auth.global.ts";
import manifest_45route_45rule from "/opt/simploshop/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_jnr4jg7ta3acnwtd7bgw6cn4di/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  cart: () => import("/opt/simploshop/shared/core-theme/app/middleware/cart.ts")
}