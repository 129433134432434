import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [ProductAvailabilityModel.ATTR_ID]: number
    [ProductAvailabilityModel.ATTR_NAME]: string | null
    [ProductAvailabilityModel.ATTR_SLUG]: string | null
    [ProductAvailabilityModel.ATTR_NOTE]: string | null
    [ProductAvailabilityModel.ATTR_CAN_PURCHASE]: boolean
    [ProductAvailabilityModel.ATTR_FOR_EMPTY_STOCK]: boolean
    [ProductAvailabilityModel.ATTR_FOR_REFILLED_STOCK]: boolean
    [ProductAvailabilityModel.ATTR_IN_CATALOG]: boolean
    [ProductAvailabilityModel.ATTR_SHOW_STOCK_STATES]: boolean
    [ProductAvailabilityModel.ATTR_HAS_AUTOMATIC_SLUG]: boolean
}

export class ProductAvailabilityModel extends ApiModel<Attributes> {
    static key = 'ProductAvailabilityModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_SLUG = 'slug'
    static readonly ATTR_NOTE = 'note'
    static readonly ATTR_CAN_PURCHASE = 'can_purchase'
    static readonly ATTR_FOR_EMPTY_STOCK = 'for_empty_stock'
    static readonly ATTR_FOR_REFILLED_STOCK = 'for_refilled_stock'
    static readonly ATTR_IN_CATALOG = 'in_catalog'
    static readonly ATTR_SHOW_STOCK_STATES = 'show_stock_states'
    static readonly ATTR_HAS_AUTOMATIC_SLUG = 'has_automatic_slug'

    get id() {
        return this._getAttribute(ProductAvailabilityModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(ProductAvailabilityModel.ATTR_NAME)
    }

    get slug() {
        return this._getAttribute(ProductAvailabilityModel.ATTR_SLUG)
    }

    get note() {
        return this._getAttribute(ProductAvailabilityModel.ATTR_NOTE)
    }

    get canPurchase() {
        return this._getAttribute(ProductAvailabilityModel.ATTR_CAN_PURCHASE)
    }

    get forEmptyStock() {
        return this._getAttribute(ProductAvailabilityModel.ATTR_FOR_EMPTY_STOCK)
    }

    get forRefilledStock() {
        return this._getAttribute(ProductAvailabilityModel.ATTR_FOR_REFILLED_STOCK)
    }

    get inCatalog() {
        return this._getAttribute(ProductAvailabilityModel.ATTR_IN_CATALOG)
    }

    get showStockStates() {
        return this._getAttribute(ProductAvailabilityModel.ATTR_SHOW_STOCK_STATES)
    }

    get hasAutomaticSlug() {
        return this._getAttribute(ProductAvailabilityModel.ATTR_HAS_AUTOMATIC_SLUG)
    }


    // ---------------------------------------------------------------------------------------------------------------------

}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================

