import { definePayloadPlugin, definePayloadReducer, definePayloadReviver } from '#imports'
import { ApiResponseError } from '../api/api.response-error'

export default definePayloadPlugin(() => {
    definePayloadReducer('ApiResponseError', (data) => {
        return data instanceof ApiResponseError
            // @ts-expect-error accessing private method of ApiResponseError
            && data.getReducedData()
    })

    definePayloadReviver('ApiResponseError', (data) => {
        return new ApiResponseError(data)
    })
})
