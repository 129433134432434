
import * as ipxRuntime$EGy1NGx32C from '/opt/simploshop/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as cloudflareRuntime$8ShKoNJImx from '/opt/simploshop/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 576,
    "md": 768,
    "lg": 992,
    "xl": 1200,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "default": {
      "modifiers": {
        "format": "webp",
        "densities": [
          1,
          2
        ]
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "demo.simploshop.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "png",
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$EGy1NGx32C, defaults: {"maxAge":3600} },
  ['cloudflare']: { provider: cloudflareRuntime$8ShKoNJImx, defaults: {"baseURL":"https://demo.simploshop.com"} }
}
        