import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [ProductListModel.ATTR_ID]: number
    [ProductListModel.ATTR_NAME]: string
    [ProductListModel.ATTR_DESCRIPTION]: string | null
    [ProductListModel.ATTR_POSITION]: number | null
}

export class ProductListModel extends ApiModel<Attributes> {
    static key = 'ProductListModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_POSITION = 'position'

    get id() {
        return this._getAttribute(ProductListModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(ProductListModel.ATTR_NAME)
    }

    get description() {
        return this._getAttribute(ProductListModel.ATTR_DESCRIPTION)
    }

    get position() {
        return this._getAttribute(ProductListModel.ATTR_POSITION)
    }
}
