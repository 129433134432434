import {
    type MaybeRefOrGetter,
    computed,
    toValue,
    type ComputedRef,
    type MaybeRef,
    getCurrentScope,
    getCurrentInstance,
    onScopeDispose
} from 'vue'
import type { MaybePromise } from 'rollup'
import { useFetch, type UseFetchOptions, useRequestEvent, useState } from 'nuxt/app'
import type { ApiModel } from '../api.model'
import { ApiResponse, type ApiResponseMeta } from '../api.response'
import { ApiResponseError } from '../api.response-error'
import { ApiReactiveUrlBuilder } from '../url-builder/api.reactive-url-builder'
import type { ConstructorType } from '../../types/utils'
import {
    apiFetch,
    type ApiServiceFetchOptions,
    getOnRequestInterceptor,
    type ServiceFetchBaseOptions,
    transformResponse,
    refreshSession,
    transformError
} from './api.service'
import { useStateHeaders } from '../../private/state'
import { useIsFetchingFromSubdomain } from '../../composables/url'
import { joinURL } from 'ufo'
import { useConfig, usePrivateConfig } from '../../private/config'
import { appendResponseHeader, H3Event } from 'h3'
import type { ServerCache, ApiRoutePrefixes } from '../../types/module'
import { useServerCache } from '../../composables/caching/caching'
import { FetchError, type FetchOptions } from 'ofetch'
import type { _AsyncData } from '#app/composables/asyncData'
import { hash } from 'ohash'
import { useApiDataRefreshCallbacks } from '../../composables/refresh'
import { errorLog } from '../../utils/logging'

type AdditionalData<T extends ApiModel, Accumulate extends boolean> = {
    item: ComputedRef<(DefaultTo<Accumulate, false> extends true ? T[] : T) | undefined>
    items: ComputedRef<T[]>
    apiError: ComputedRef<ApiResponseError | null>
}

type CustomAsyncData<Data, Error, Accumulate extends boolean> = _AsyncData<Data, Error> & AdditionalData<Data extends ApiResponse<infer M> ? M : never, Accumulate>
    & Promise<_AsyncData<Data, Error> & AdditionalData<Data extends ApiResponse<infer M> ? M : never, Accumulate>>

type DefaultTo<T, U> = T extends undefined ? U : T

type ApiServiceOptions = ({
    url: MaybeRefOrGetter<string>
    endpoint?: never
} | {
    url?: never
    endpoint: MaybeRefOrGetter<string>
}) & {
    routePrefix?: MaybeRefOrGetter<keyof ApiRoutePrefixes>
}

export type ApiServiceUseFetchOptions<T extends ApiModel, A extends boolean> = Partial<ServiceUseFetchOptions<T, A>>

interface SSRCacheOptions {
    key: keyof ServerCache
    /**
     * The time in seconds after which the cache entry should expire.
     */
    ttl?: number | null
    /**
     * Whether the cache entry should be revalidated in the background when
     * the data is requested on the client.
     *
     * However, if no data is available in the cache, the request will be made
     * immediately. (blocking)
     * @default false
     */
    swr?: boolean
}

type ServiceUseFetchOptions<T extends ApiModel, A extends boolean = false> = {
    /**
     * Whether the request should be made immediately.
     *
     * @default true
     */
    immediate: boolean
    /**
     * Whether the request should automatically re-fetch when the `watch` dependencies change.
     *
     * @default true
     */
    watch: boolean
    /**
     * Whether the request should be made on the server-side.
     *
     * @default true
     */
    server: boolean
    /**
     * Whether the request should be lazy (not blocking navigation).
     * For true lazy requests (not blocking even the first render), use `server: false`
     * in combination with `lazy: true`.
     *
     * @default false
     */
    lazy: boolean
    /**
     * The cache key to use for the request in server-side cache.
     * If not provided, the request will not be cached.
     *
     * An object with options can be provided instead of a single key to
     * set additional settings for the cache entry like TTL, for example.
     *
     * To add cache keys, define them in an `index.d.ts` file for the following interface:
     * @example Adding cache keys
     * declare module '@composable-api-types/module' {
     *     interface ServerCache {
     *         'products': ApiResponse<ProductModel>
     *     }
     * }
     *
     * @default undefined
     */
    ssrCache: keyof ServerCache | SSRCacheOptions
    /**
     * Whether subsequent requests should be accumulated with the previous ones.
     * This doesn't override the previous response data, but appends new data to it.
     * This is useful for paginated requests (when implementing infinite scrolling, for example).
     */
    accumulate: A
    /**
     * A function to call after the response is received.
     * This is useful in combination with reactive api calls. (e.g. refreshing the data
     * & assigning it to a reactive variable on response)
     * @param response The response of the API call.
     */
    onResponse: (response: ApiResponse<T>, accumulated: ApiResponse<T>[]) => MaybePromise<void>
    /**
     * A function to call when an error occurs during the request.
     * This can be useful to display a notification to the user, for example.
     * @param error The api error that occurred.
     */
    onError: (error: ApiResponseError) => MaybePromise<void>
    /**
     * Whether to pass cookies from the SSR call to the client.
     * @default false
     */
    forwardCookiesFromSSR?: boolean
    /**
     * Whether to exclude the current reactive fetch call from the global data
     * refresh event.
     *
     * @see useApiDataRefresh
     */
    excludeFromDataRefresh?: boolean
}


export class ApiReactiveService<T extends ApiModel> extends ApiReactiveUrlBuilder<T> {
    private readonly model: ConstructorType<T> | null = null
    private _apiUrl: string

    constructor(options: ApiServiceOptions, model: ConstructorType<T> | null) {
        if (!getCurrentScope()) {
            throw new Error('Reactive ApiService can only be used within the script setup block of a component. ' +
                'You can use the non-reactive ApiService in other places.')
        }

        // capture the values into separate variables because `useConfig()` cannot be passed into
        // the `super()` call directly, because it is a composable and that would make the reactive
        // api url builder not usable in places where the Nuxt instance is not available
        const config = useConfig()
        const privateConfig = usePrivateConfig()
        const apiUrl = (
            import.meta.server
                ? privateConfig.apiUrlSsr || config.apiUrl
                : config.apiUrl
        ) ?? ''
        const apiRoutePrefix = toValue(options.routePrefix) ?? config.apiRoutePrefix ?? ''


        super({
            url: () =>
                toValue(options.url) ??
                joinURL(
                    apiUrl,
                    apiRoutePrefix,
                    toValue(options.endpoint) ?? ''
                ),
        })

        this.model = model
        this._apiUrl = joinURL(
            config.apiUrl ?? '',
            apiRoutePrefix,
            toValue(options.endpoint) ?? ''
        )
    }

    // AsyncData<PickFrom<DataT, PickKeys> | DefaultT, ErrorT | DefaultAsyncDataErrorValue>

    protected useFetch<A extends boolean>(options?: Partial<ApiServiceUseFetchOptions<T, A> & ServiceFetchBaseOptions<any>>): CustomAsyncData<ApiResponse<T> | undefined, FetchError<any>, A> {
        if (import.meta.dev && options?.ssrCache && (options.server === false || (options.method && options.method.toUpperCase() !== 'GET'))) {
            console.warn('[useFetch]: The `ssrCache` option only makes sense with server-side GET requests.')
        }
        // ------------------------------------------------------------------------------
        const builderData = this.captureBuilderDataAndReset()

        let responseMeta: Omit<ApiResponseMeta, 'body'> | null = null

        // TODO: document these headers & add an option to react to headers change
        const headers = useStateHeaders()

        const _isFetchingFromSubdomain = useIsFetchingFromSubdomain(builderData.builtUrl)
        const credentialsOption = computed(() => _isFetchingFromSubdomain.value ? 'include' : undefined)
        let hasRetriedAfterUnauthorized = false

        const { getCacheUrl, setData, isCachingEnabled } = useServerCache()

        const cacheKey = typeof options?.ssrCache === 'object'
            ? options.ssrCache.key as string
            : options?.ssrCache as string | undefined

        const url = computed(() =>
            options?.ssrCache && isCachingEnabled.value
            && (options.method === undefined || options.method.toUpperCase() === 'GET')
            && options.server !== false
                ? getCacheUrl(cacheKey as keyof ServerCache)
                : toValue(builderData.builtUrl)
        )

        const keySegments = [builderData.getBuiltUrl(this._apiUrl), ...generateOptionSegments({
            params: builderData.builtParams,
            immediate: options?.immediate,
            watch: options?.watch as any,
            server: options?.server,
            lazy: options?.lazy,
            method: options?.method,
            body: options?.body,
        })]
        const key = `$cf${hash(keySegments)}`

        const apiErrors = useState<Record<string, ApiResponseError | null> | null>(() => null)

        // TODO: type request context correctly
        const interceptResponse = ({ response, error }: any, event: H3Event | undefined) => {
            responseMeta = {
                status: response.status,
                headers: JSON.parse(JSON.stringify(response.headers)),
            }

            // forward cookies to the client
            if (options?.forwardCookiesFromSSR && event) {
                const cookies = response.headers.getSetCookie()
                for (const cookie of cookies) {
                    appendResponseHeader(event, 'set-cookie', cookie)
                }
            }
        }

        let event: H3Event | undefined
        let isFetchingFromSubdomain: boolean

        const asyncData = useFetch(url, {
            params: builderData.builtParams,
            onRequest: (context) => {
                // reset the error on each request
                if (apiErrors.value?.[key] !== undefined) apiErrors.value[key] = null

                event = useRequestEvent()
                isFetchingFromSubdomain = _isFetchingFromSubdomain.value

                // Do not use the cached data if the request was made on the client
                if (options?.ssrCache && import.meta.client) {
                    context.request = builderData.builtUrl.value
                }

                return getOnRequestInterceptor(
                    event,
                    isFetchingFromSubdomain,
                    headers.value as Record<string, string>
                )(context)
            },
            onResponse: (response) => interceptResponse(response, event),
            onResponseError: async ({ response }) => {
                // set response error
                if (!apiErrors.value) apiErrors.value = {}
                apiErrors.value[key] = transformError(response)
                options?.onError?.(apiErrors.value[key]!)

                if (response.status !== 401 || hasRetriedAfterUnauthorized) {
                    // TODO: Add support for ApiResponseError
                    // throw transformError(response)
                    return
                }

                hasRetriedAfterUnauthorized = true

                const wasSessionRefreshed = await refreshSession(
                    event,
                    isFetchingFromSubdomain
                )

                if (!wasSessionRefreshed) {
                    // The session was not refreshed, so we should not retry the request
                    return
                }

                // Retry the request
                await asyncData.refresh()
            },
            transform: async (data) => {
                let _data = data
                const isCacheEmpty = responseMeta?.status === 204
                const isSwrEnabled = typeof options?.ssrCache === 'object' && options.ssrCache.swr
                // Replace the data with the data from the cache, if applicable
                // (should use cache, is on the server & the cache has no data)
                if (options?.ssrCache && isCachingEnabled.value && import.meta.server && (isCacheEmpty || isSwrEnabled)) {
                    try {
                        const executeFetch = () => $fetch(builderData.builtUrl.value, {
                            params: builderData.builtParams.value,
                            headers: headers.value as Record<string, string>,
                            onRequest: getOnRequestInterceptor(
                                event,
                                isFetchingFromSubdomain,
                                headers.value as Record<string, string>
                            ),
                            onResponse: (response) => interceptResponse(response, event),
                            onResponseError: async ({ response }) => {
                                if (response.status !== 401 || hasRetriedAfterUnauthorized) {
                                    // TODO: Add support for ApiResponseError
                                    // throw transformError(response)
                                    return
                                }

                                hasRetriedAfterUnauthorized = true

                                const wasSessionRefreshed = await refreshSession(
                                    event,
                                    isFetchingFromSubdomain
                                )

                                if (!wasSessionRefreshed) {
                                    // The session was not refreshed, so we should not retry the request
                                    return
                                }

                                _data = await executeFetch()
                            },
                        })

                        const cacheOptions = (typeof options.ssrCache === 'object' ? options.ssrCache : undefined) as SSRCacheOptions | undefined

                        // fetch data from the data source url
                        if (isCacheEmpty) {
                            _data = await executeFetch()

                            // set the data to the cache
                            if (_data) {
                                await setData(cacheKey as keyof ServerCache, _data as never, {
                                    ttl: cacheOptions?.ttl,
                                })
                            }
                        } else if (isSwrEnabled) {
                            // make a background refresh of the cached data
                            new Promise<void>(async (resolve) => {
                                const updatedData = await executeFetch()

                                // update the data in the cache
                                if (updatedData) {
                                    await setData(cacheKey as keyof ServerCache, updatedData as never, {
                                        ttl: cacheOptions?.ttl,
                                    })
                                }
                                resolve()
                            })
                        }
                    } catch (e) {
                        if (e instanceof ApiResponseError) {
                            throw e
                        }
                        console.error('[useFetch] Error fetching data from original url for cache', e)
                    }
                }

                /*
                // TODO: in the future, we might want to cache the response based on query params too
                else if (options?.ssrCache && import.meta.client) {
                    console.log('FETCHING ON THE CLIENT!')
                }
                 */

                const transformedResponse = transformResponse(this.model, _data, responseMeta)

                if (options?.accumulate) {
                    if (Array.isArray(asyncData.data.value)) {
                        const accumulatedResponses = asyncData.data.value as ApiResponse<T>[]
                        accumulatedResponses.push(transformedResponse)
                        options?.onResponse?.(transformedResponse, accumulatedResponses)
                        return accumulatedResponses as unknown as ApiResponse<T>    // TODO: replace later with correct generic typing
                    }
                    return [transformedResponse] as unknown as ApiResponse<T>       // TODO: replace later with correct generic typing
                }

                options?.onResponse?.(transformedResponse, [])
                return transformedResponse
            },
            immediate: options?.immediate,
            watch: options?.watch === false ? false : [builderData.builtUrl, ...(options?.watch as unknown as any[] || [])],
            server: options?.server,
            lazy: options?.lazy,
            method: options?.method,
            body: options?.body,
            credentials: credentialsOption,
            key: key,
        })

        // register the refresh event callback
        if (import.meta.client && !options?.excludeFromDataRefresh) {
            const refreshData = async () => {
                await asyncData.refresh()
            }

            const { hook } = useApiDataRefreshCallbacks()
            const hasScope = getCurrentScope()
            const type = getCurrentInstance() ? 'component' : hasScope ? 'scope' : null

            if (!type) {
                errorLog('[useFetch]: Could not determine the type of the context where the data is fetched.', {
                    url: url.value,
                    key: key,
                })
            } else {
                const unhook = hook({
                    type: type,
                    refresh: refreshData,
                })

                if (hasScope) {
                    onScopeDispose(unhook)
                }
            }
        }

        const additionalData = {
            /**
             * The model from the ApiResponse.
             * This computed property holds the result of `ApiResponse.getItem()`.
             *
             * When used with `accumulate: true`, this will be an array of models.
             */
            item: computed(() => options?.accumulate
                ? (asyncData.data.value as ApiResponse<T>[] | undefined)?.map(response => response.getItem()) ?? []
                : (asyncData.data.value as ApiResponse<T> | undefined)?.getItem() ?? null
            ) as ComputedRef<(DefaultTo<A, false> extends true ? T[] : T) | undefined>,
            /**
             * An array of the models from the ApiResponse.
             * This computed property holds the result of `ApiResponse.getItems()`.
             *
             * When used with `accumulate: true`, this will be a flattened array of arrays of models.
             */
            items: computed(() => options?.accumulate
                ? (asyncData.data.value as ApiResponse<T>[] | undefined)?.flatMap(response => response.getItems()) ?? []
                : (asyncData.data.value as ApiResponse<T> | undefined)?.getItems() ?? []
            ) as ComputedRef<T[]>,
            apiError: computed(() => apiErrors.value?.[key] ?? null),
        }

        const asyncDataPromise =
            Promise.resolve(asyncData).then(data => Object.assign(data, additionalData))
        Object.assign(asyncDataPromise, asyncData, additionalData)
        return asyncDataPromise as unknown as (typeof asyncData & typeof additionalData) & typeof asyncDataPromise
    }

    // -----------------------------------------------------------------------------------------------------------------

    protected async fetch(options?: Partial<ApiServiceFetchOptions<T> & ServiceFetchBaseOptions<any>>) {
        // TODO: dispose of effects
        const builderData = this.captureBuilderDataAndReset()

        const isFetchingFromSubdomain = useIsFetchingFromSubdomain(builderData.builtUrl)

        // TODO: document these headers & add an option to react to headers change
        const headers = useStateHeaders()

        const response = await apiFetch({
            model: this.model,
            url: builderData.builtUrl.value,
            usageOptions: options,
            event: useRequestEvent(),
            isFetchingFromSubdomain: isFetchingFromSubdomain.value,
            headers: headers.value as Record<string, string>,
            params: builderData.builtParams.value,
        })

        return response!
    }

}

// function from Nuxt source
function generateOptionSegments<_ResT, DataT, DefaultT>(opts: UseFetchOptions<_ResT, DataT, any, DefaultT, any, any>) {
    const segments: Array<string | undefined | Record<string, string>> = [
        toValue(opts.method as MaybeRef<string | undefined> | undefined)?.toUpperCase() || 'GET',
        toValue(opts.baseURL),
    ]
    for (const _obj of [opts.params || opts.query]) {
        const obj = toValue(_obj)
        if (!obj) { continue }

        const unwrapped: Record<string, string> = {}
        for (const [key, value] of Object.entries(obj)) {
            unwrapped[toValue(key)] = toValue(value)
        }
        segments.push(unwrapped)
    }
    return segments
}
