import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [ArticleCategoryModel.ATTR_ID]: number
    [ArticleCategoryModel.ATTR_DESCRIPTION]: string | null
    [ArticleCategoryModel.ATTR_NAME]: string | null
    [ArticleCategoryModel.ATTR_PARENT_ID]: string | null
}

interface Embeds {
    [ArticleCategoryModel.EMBED_URLS]: string
}

export class ArticleCategoryModel extends ApiModel<Attributes, Embeds> {
    static key = 'ArticleCategoryModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_PARENT_ID = 'parent_id'

    static readonly EMBED_URLS = 'urls'

    get id() {
        return this._getAttribute(ArticleCategoryModel.ATTR_ID)
    }

    get description() {
        return this._getAttribute(ArticleCategoryModel.ATTR_DESCRIPTION)
    }

    get name() {
        return this._getAttribute(ArticleCategoryModel.ATTR_NAME)
    }

    get parentId() {
        return this._getAttribute(ArticleCategoryModel.ATTR_PARENT_ID)
    }

    get urls() {
        return this._getEmbed(ArticleCategoryModel.EMBED_URLS)
    }
}
