import { ProductVariationPropertyType } from '../../enums/product'
import type { ApiDateTime } from '../../types/general-data'
import { ApiModel } from '@composable-api/api.model'
import { ProductVariationPropertyAttributeModel } from './product-variation-property-attribute.model'

interface ProductVariationPropertyModelAttributes {
    [ProductVariationPropertyModel.ATTR_ID]: number
    [ProductVariationPropertyModel.ATTR_NAME]: string
    [ProductVariationPropertyModel.ATTR_DESCRIPTION]: string | null
    [ProductVariationPropertyModel.ATTR_POSITION]: number
    [ProductVariationPropertyModel.ATTR_IS_PUBLIC]: boolean
    [ProductVariationPropertyModel.ATTR_HAS_AUTOMATIC_SLUG]: boolean
    [ProductVariationPropertyModel.ATTR_SLUG]: string
    [ProductVariationPropertyModel.ATTR_TYPE]: ProductVariationPropertyType
    [ProductVariationPropertyModel.ATTR_CREATED_AT]: ApiDateTime
    [ProductVariationPropertyModel.ATTR_PRODUCT_VARIATION_PROPERTY_ATTRIBUTES]: ProductVariationPropertyAttributeModel[]
}

export class ProductVariationPropertyModel extends ApiModel<ProductVariationPropertyModelAttributes> {
    static key = 'ProductVariationPropertyModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_POSITION = 'position'
    static readonly ATTR_IS_PUBLIC = 'is_public'
    static readonly ATTR_HAS_AUTOMATIC_SLUG = 'has_automatic_slug'
    static readonly ATTR_SLUG = 'slug'
    static readonly ATTR_TYPE = 'type'
    static readonly ATTR_CREATED_AT = 'created_at'
    static readonly ATTR_PRODUCT_VARIATION_PROPERTY_ATTRIBUTES = 'product_variation_property_attributes'

    get id() {
        return this._getAttribute(ProductVariationPropertyModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(ProductVariationPropertyModel.ATTR_NAME)
    }

    get description() {
        return this._getAttribute(ProductVariationPropertyModel.ATTR_DESCRIPTION)
    }

    get position() {
        return this._getAttribute(ProductVariationPropertyModel.ATTR_POSITION)
    }

    get isPublic() {
        return this._getAttribute(ProductVariationPropertyModel.ATTR_IS_PUBLIC)
    }

    get hasAutomaticSlug() {
        return this._getAttribute(ProductVariationPropertyModel.ATTR_HAS_AUTOMATIC_SLUG)
    }

    get slug() {
        return this._getAttribute(ProductVariationPropertyModel.ATTR_SLUG)
    }

    get type() {
        return this._getAttribute(ProductVariationPropertyModel.ATTR_TYPE)
    }

    get createdAt() {
        return this._getAttribute(ProductVariationPropertyModel.ATTR_CREATED_AT)
    }

    private get productVariationPropertyAttributes() {
        return this._getAttribute(ProductVariationPropertyModel.ATTR_PRODUCT_VARIATION_PROPERTY_ATTRIBUTES, ProductVariationPropertyAttributeModel)
    }

    // ------------------------------------------------------------

    /**
     * Get the attributes of the variation property.
     *
     * Only includes the attributes that are not hidden.
     */
    getAttributes() {
        return this.productVariationPropertyAttributes?.filter(attr => !attr.isHidden) ?? []
    }

    isTypeSelect() {
        return this.type === ProductVariationPropertyType.Select
    }

    isTypeRadio() {
        return this.type === ProductVariationPropertyType.Radio
    }

    isTypeColor() {
        return this.type === ProductVariationPropertyType.Color
    }

    isTypeText() {
        return this.type === ProductVariationPropertyType.TextInput
    }
}
