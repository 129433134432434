import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [StaticPageModel.ATTR_ID]: number
    [StaticPageModel.ATTR_CONTENT]: string
}

export class StaticPageModel extends ApiModel<Attributes> {
    static key = 'StaticPageModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_CONTENT = 'content'

    get id() {
        return this._getAttribute(StaticPageModel.ATTR_ID)
    }

    get content() {
        return this._getAttribute(StaticPageModel.ATTR_CONTENT)
    }
}
