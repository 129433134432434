import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [ProductAttachmentModel.ATTR_ID]: number
    [ProductAttachmentModel.ATTR_PRODUCT_ID]: number
    [ProductAttachmentModel.ATTR_ATTACHMENT_ID]: number
    [ProductAttachmentModel.ATTR_POSITION]: number
    [ProductAttachmentModel.ATTR_DESCRIPTION]: string | null
    [ProductAttachmentModel.ATTR_DOWNLOAD_URL]: string | null
}

export class ProductAttachmentModel extends ApiModel<Attributes> {
    static key = 'ProductAttachmentModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_PRODUCT_ID = 'product_id'
    static readonly ATTR_ATTACHMENT_ID = 'attachment_id'
    static readonly ATTR_POSITION = 'position'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_DOWNLOAD_URL = 'download_url'

    get id() {
        return this._getAttribute(ProductAttachmentModel.ATTR_ID)
    }

    get productId() {
        return this._getAttribute(ProductAttachmentModel.ATTR_PRODUCT_ID)
    }

    get attachmentId() {
        return this._getAttribute(ProductAttachmentModel.ATTR_ATTACHMENT_ID)
    }

    get position() {
        return this._getAttribute(ProductAttachmentModel.ATTR_POSITION)
    }

    get description() {
        return this._getAttribute(ProductAttachmentModel.ATTR_DESCRIPTION)
    }

    get downloadUrl() {
        return this._getAttribute(ProductAttachmentModel.ATTR_DOWNLOAD_URL)
    }


    // ---------------------------------------------------------------------------------------------------------------------

}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================

