<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="81" height="91" fill="none">
        <circle cx="36.5" cy="23.5" r="21.5" stroke="#fff" stroke-width="4" />
        <path stroke="#fff" stroke-linecap="round" stroke-width="4"
            d="M71 89c0-18.225-15.446-33-34.5-33C17.446 56 2 70.775 2 89"
        />
        <path fill="#FF0059"
            d="M71 58a1.818 1.818 0 0 1-1.818-1.818v-6.364h-6.364a1.818 1.818 0 1 1 0-3.636h6.364v-6.364a1.818 1.818 0 1 1 3.636 0v6.364h6.364a1.818 1.818 0 1 1 0 3.636h-6.364v6.364A1.818 1.818 0 0 1 71 58Z"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
