import type { FormattedCurrency } from '../types/general-data'
import { ApiModel } from '@composable-api/api.model'
import { FormattedCurrencyModel } from '../models/custom/formatted-currency.model'

interface Attributes {
    [CartShippingMethodModel.ATTR_ID]: number
    [CartShippingMethodModel.ATTR_NAME]: string | null
    [CartShippingMethodModel.ATTR_PRICE]: FormattedCurrency
    [CartShippingMethodModel.ATTR_DESCRIPTION]: string | null
    [CartShippingMethodModel.ATTR_TAXED_PRICE]: FormattedCurrency
    [CartShippingMethodModel.ATTR_CAN_USE]: boolean
    [CartShippingMethodModel.ATTR_CAN_USE_BY_COUNTRY]: boolean
    [CartShippingMethodModel.ATTR_HAS_OPTIONS]: boolean
    [CartShippingMethodModel.ATTR_OPTION_ID]: string | null
    [CartShippingMethodModel.ATTR_DELIVERY_TIME]: string | null
    [CartShippingMethodModel.ATTR_SHIPPING_SERVICE]: number | null
    [CartShippingMethodModel.ATTR_ADDRESS_REQUIREMENT]: number
    [CartShippingMethodModel.ATTR_IMAGE_ID]: number | null
    [CartShippingMethodModel.ATTR_POSITION]: number
}

interface Embeds {
    [CartShippingMethodModel.EMBED_IMAGE_URL]: string | null
}

export class CartShippingMethodModel extends ApiModel<Attributes, Embeds> {
    static key = 'CartShippingMethodModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_PRICE = 'price'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_TAXED_PRICE = 'taxed_price'
    static readonly ATTR_CAN_USE = 'can_use'
    static readonly ATTR_CAN_USE_BY_COUNTRY = 'can_be_used_by_country'
    static readonly ATTR_HAS_OPTIONS = 'has_options'
    static readonly ATTR_OPTION_ID = 'option_id'
    static readonly ATTR_DELIVERY_TIME = 'delivery_time'
    static readonly ATTR_SHIPPING_SERVICE = 'shipping_service'
    static readonly ATTR_ADDRESS_REQUIREMENT = 'address_requirement'
    static readonly ATTR_IMAGE_ID = 'image_id'
    static readonly ATTR_POSITION = 'position'

    static readonly EMBED_IMAGE_URL = 'image_url'

    get id() {
        return this._getAttribute(CartShippingMethodModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(CartShippingMethodModel.ATTR_NAME)
    }

    get price() {
        return this._getAttribute(CartShippingMethodModel.ATTR_PRICE, FormattedCurrencyModel)
    }

    get description() {
        return this._getAttribute(CartShippingMethodModel.ATTR_DESCRIPTION)
    }

    get taxedPrice() {
        return this._getAttribute(CartShippingMethodModel.ATTR_TAXED_PRICE, FormattedCurrencyModel)
    }

    get canUse() {
        return this._getAttribute(CartShippingMethodModel.ATTR_CAN_USE)
    }

    get canUseByCountry() {
        return this._getAttribute(CartShippingMethodModel.ATTR_CAN_USE_BY_COUNTRY)
    }

    get hasOptions() {
        return this._getAttribute(CartShippingMethodModel.ATTR_HAS_OPTIONS)
    }

    get optionId() {
        return this._getAttribute(CartShippingMethodModel.ATTR_OPTION_ID)
    }

    get deliveryTime() {
        return this._getAttribute(CartShippingMethodModel.ATTR_DELIVERY_TIME)
    }

    get shippingService() {
        return this._getAttribute(CartShippingMethodModel.ATTR_SHIPPING_SERVICE)
    }

    get addressRequirement() {
        return this._getAttribute(CartShippingMethodModel.ATTR_ADDRESS_REQUIREMENT)
    }

    get imageId() {
        return this._getAttribute(CartShippingMethodModel.ATTR_IMAGE_ID)
    }

    get position() {
        return this._getAttribute(CartShippingMethodModel.ATTR_POSITION)
    }

    get imageUrl() {
        return this._getEmbed(CartShippingMethodModel.EMBED_IMAGE_URL)
    }
}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================

