
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93yri8I4l3CyMeta } from "/opt/simploshop/shared/core-theme/app/pages/[...slug].vue?macro=true";
import { default as deliveryOkL4Z0jZsOMeta } from "/opt/simploshop/themes/demo/app/pages/cart/delivery.vue?macro=true";
import { default as indexHSVUx0MnrYMeta } from "/opt/simploshop/themes/demo/app/pages/cart/index.vue?macro=true";
import { default as payment_45summaryDIocFAyioAMeta } from "/opt/simploshop/themes/demo/app/pages/cart/payment-summary.vue?macro=true";
import { default as shipping_45and_45paymentRmZ2A2n14SMeta } from "/opt/simploshop/themes/demo/app/pages/cart/shipping-and-payment.vue?macro=true";
import { default as summaryse1axEdsMpMeta } from "/opt/simploshop/themes/demo/app/pages/cart/summary.vue?macro=true";
import { default as cartTeOkz3MSAQMeta } from "/opt/simploshop/themes/demo/app/pages/cart.vue?macro=true";
import { default as account0EBAYIr9lzMeta } from "/opt/simploshop/themes/demo/app/pages/customer/account.vue?macro=true";
import { default as dashboardPbi42lVBuQMeta } from "/opt/simploshop/themes/demo/app/pages/customer/dashboard.vue?macro=true";
import { default as favorite_45productsSWqhHs2SOAMeta } from "/opt/simploshop/themes/demo/app/pages/customer/favorite-products.vue?macro=true";
import { default as my_45addressesATpBi8vxSLMeta } from "/opt/simploshop/themes/demo/app/pages/customer/my-addresses.vue?macro=true";
import { default as _91id_935S5r7gHiXHMeta } from "/opt/simploshop/themes/demo/app/pages/customer/my-orders/[id].vue?macro=true";
import { default as indexuSWIZ8pK3mMeta } from "/opt/simploshop/themes/demo/app/pages/customer/my-orders/index.vue?macro=true";
import { default as my_45ordersOad7ORrAxNMeta } from "/opt/simploshop/themes/demo/app/pages/customer/my-orders.vue?macro=true";
import { default as customerZ0v5Szw117Meta } from "/opt/simploshop/themes/demo/app/pages/customer.vue?macro=true";
import { default as indexPbfF3hXLKPMeta } from "/opt/simploshop/themes/demo/app/pages/index.vue?macro=true";
import { default as loginIJ1WVVF8ZqMeta } from "/opt/simploshop/themes/demo/app/pages/login.vue?macro=true";
import { default as password_45resetkk81xsp5ebMeta } from "/opt/simploshop/themes/demo/app/pages/password-reset.vue?macro=true";
export default [
  {
    name: "slug___cs",
    path: "/:slug(.*)*",
    component: () => import("/opt/simploshop/shared/core-theme/app/pages/[...slug].vue")
  },
  {
    name: "slug___sk",
    path: "/:slug(.*)*",
    component: () => import("/opt/simploshop/shared/core-theme/app/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/opt/simploshop/shared/core-theme/app/pages/[...slug].vue")
  },
  {
    name: cartTeOkz3MSAQMeta?.name,
    path: "/cart",
    meta: cartTeOkz3MSAQMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart.vue"),
    children: [
  {
    name: "cart-delivery___cs",
    path: "/kosik/doruceni",
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart/delivery.vue")
  },
  {
    name: "cart___cs",
    path: "/kosik",
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart/index.vue")
  },
  {
    name: "cart-payment-summary___cs",
    path: "/kosik/shrnuti-platby",
    meta: payment_45summaryDIocFAyioAMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart/payment-summary.vue")
  },
  {
    name: "cart-shipping-and-payment___cs",
    path: "/kosik/doprava-a-platba",
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart/shipping-and-payment.vue")
  },
  {
    name: "cart-summary___cs",
    path: "/kosik/shrnuti",
    meta: summaryse1axEdsMpMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart/summary.vue")
  }
]
  },
  {
    name: cartTeOkz3MSAQMeta?.name,
    path: "/cart",
    meta: cartTeOkz3MSAQMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart.vue"),
    children: [
  {
    name: "cart-delivery___sk",
    path: "/kosik/dorucenie",
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart/delivery.vue")
  },
  {
    name: "cart___sk",
    path: "/kosik",
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart/index.vue")
  },
  {
    name: "cart-payment-summary___sk",
    path: "/kosik/zhrnutie-platby",
    meta: payment_45summaryDIocFAyioAMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart/payment-summary.vue")
  },
  {
    name: "cart-shipping-and-payment___sk",
    path: "/kosik/doprava-a-platba",
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart/shipping-and-payment.vue")
  },
  {
    name: "cart-summary___sk",
    path: "/kosik/zhrnutie",
    meta: summaryse1axEdsMpMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart/summary.vue")
  }
]
  },
  {
    name: cartTeOkz3MSAQMeta?.name,
    path: "/cart",
    meta: cartTeOkz3MSAQMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart.vue"),
    children: [
  {
    name: "cart-delivery___en",
    path: "delivery",
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart/delivery.vue")
  },
  {
    name: "cart___en",
    path: "/my-cart",
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart/index.vue")
  },
  {
    name: "cart-payment-summary___en",
    path: "payment-summary",
    meta: payment_45summaryDIocFAyioAMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart/payment-summary.vue")
  },
  {
    name: "cart-shipping-and-payment___en",
    path: "shipping-and-payment",
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart/shipping-and-payment.vue")
  },
  {
    name: "cart-summary___en",
    path: "summary",
    meta: summaryse1axEdsMpMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/cart/summary.vue")
  }
]
  },
  {
    name: "customer___cs",
    path: "/customer",
    meta: customerZ0v5Szw117Meta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer.vue"),
    children: [
  {
    name: "customer-account___cs",
    path: "/zakaznik/informace-o-uctu",
    meta: account0EBAYIr9lzMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/account.vue")
  },
  {
    name: "customer-dashboard___cs",
    path: "/zakaznik/dashboard",
    meta: dashboardPbi42lVBuQMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/dashboard.vue")
  },
  {
    name: "customer-favorite-products___cs",
    path: "/zakaznik/oblibene-produkty",
    meta: favorite_45productsSWqhHs2SOAMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/favorite-products.vue")
  },
  {
    name: "customer-my-addresses___cs",
    path: "/zakaznik/adresar",
    meta: my_45addressesATpBi8vxSLMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/my-addresses.vue")
  },
  {
    name: my_45ordersOad7ORrAxNMeta?.name,
    path: "/zakaznik/moje-objednavky",
    meta: my_45ordersOad7ORrAxNMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/my-orders.vue"),
    children: [
  {
    name: "customer-my-orders-id___cs",
    path: "/zakaznik/objednavka/:id()",
    meta: _91id_935S5r7gHiXHMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/my-orders/[id].vue")
  },
  {
    name: "customer-my-orders___cs",
    path: "",
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/my-orders/index.vue")
  }
]
  }
]
  },
  {
    name: "customer___sk",
    path: "/customer",
    meta: customerZ0v5Szw117Meta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer.vue"),
    children: [
  {
    name: "customer-account___sk",
    path: "/zakaznik/informacie-o-ucte",
    meta: account0EBAYIr9lzMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/account.vue")
  },
  {
    name: "customer-dashboard___sk",
    path: "/zakaznik/dashboard",
    meta: dashboardPbi42lVBuQMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/dashboard.vue")
  },
  {
    name: "customer-favorite-products___sk",
    path: "/zakaznik/oblubene-produkty",
    meta: favorite_45productsSWqhHs2SOAMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/favorite-products.vue")
  },
  {
    name: "customer-my-addresses___sk",
    path: "/zakaznik/adresar",
    meta: my_45addressesATpBi8vxSLMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/my-addresses.vue")
  },
  {
    name: my_45ordersOad7ORrAxNMeta?.name,
    path: "/zakaznik/moje-objednavky",
    meta: my_45ordersOad7ORrAxNMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/my-orders.vue"),
    children: [
  {
    name: "customer-my-orders-id___sk",
    path: "/zakaznik/objednavka/:id()",
    meta: _91id_935S5r7gHiXHMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/my-orders/[id].vue")
  },
  {
    name: "customer-my-orders___sk",
    path: "",
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/my-orders/index.vue")
  }
]
  }
]
  },
  {
    name: "customer___en",
    path: "/customer",
    meta: customerZ0v5Szw117Meta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer.vue"),
    children: [
  {
    name: "customer-account___en",
    path: "account",
    meta: account0EBAYIr9lzMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/account.vue")
  },
  {
    name: "customer-dashboard___en",
    path: "dashboard",
    meta: dashboardPbi42lVBuQMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/dashboard.vue")
  },
  {
    name: "customer-favorite-products___en",
    path: "favorite-products",
    meta: favorite_45productsSWqhHs2SOAMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/favorite-products.vue")
  },
  {
    name: "customer-my-addresses___en",
    path: "my-addresses",
    meta: my_45addressesATpBi8vxSLMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/my-addresses.vue")
  },
  {
    name: my_45ordersOad7ORrAxNMeta?.name,
    path: "my-orders",
    meta: my_45ordersOad7ORrAxNMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/my-orders.vue"),
    children: [
  {
    name: "customer-my-orders-id___en",
    path: "/customer/order/:id()",
    meta: _91id_935S5r7gHiXHMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/my-orders/[id].vue")
  },
  {
    name: "customer-my-orders___en",
    path: "",
    component: () => import("/opt/simploshop/themes/demo/app/pages/customer/my-orders/index.vue")
  }
]
  }
]
  },
  {
    name: "index___cs",
    path: "/",
    meta: indexPbfF3hXLKPMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/index.vue")
  },
  {
    name: "index___sk",
    path: "/",
    meta: indexPbfF3hXLKPMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexPbfF3hXLKPMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/index.vue")
  },
  {
    name: "login___cs",
    path: "/prihlaseni",
    meta: loginIJ1WVVF8ZqMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/login.vue")
  },
  {
    name: "login___sk",
    path: "/prihlasenie",
    meta: loginIJ1WVVF8ZqMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginIJ1WVVF8ZqMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/login.vue")
  },
  {
    name: "password-reset___cs",
    path: "/obnova-hesla",
    meta: password_45resetkk81xsp5ebMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/password-reset.vue")
  },
  {
    name: "password-reset___sk",
    path: "/obnova-hesla",
    meta: password_45resetkk81xsp5ebMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/password-reset.vue")
  },
  {
    name: "password-reset___en",
    path: "/password-reset",
    meta: password_45resetkk81xsp5ebMeta || {},
    component: () => import("/opt/simploshop/themes/demo/app/pages/password-reset.vue")
  }
]