import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [FormattedCurrencyModel.ATTR_VALUE]: number
    [FormattedCurrencyModel.ATTR_FORMATTED]: string
}

export class FormattedCurrencyModel<TAttributes = unknown> extends ApiModel<Attributes & TAttributes> {
    static key = 'FormattedCurrencyModel'

    static readonly ATTR_VALUE = 'value'
    static readonly ATTR_FORMATTED = 'formatted'

    get value() {
        return this._getAttribute(FormattedCurrencyModel.ATTR_VALUE)!
    }

    get formatted() {
        return this._getAttribute(FormattedCurrencyModel.ATTR_FORMATTED)!
    }
}
