import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [UserSessionModel.ATTR_ID]: number
    [UserSessionModel.ATTR_USER_ID]: number
    [UserSessionModel.ATTR_ACCESS_TOKEN]: string
    [UserSessionModel.ATTR_REFRESH_TOKEN]: string
    [UserSessionModel.ATTR_SESSION_EXPIRATION_AT]: string
}

export class UserSessionModel extends ApiModel<Attributes> {
    static key = 'UserSessionModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_USER_ID = 'user_id'
    static readonly ATTR_ACCESS_TOKEN = 'access_token'
    static readonly ATTR_REFRESH_TOKEN = 'refresh_token'
    static readonly ATTR_SESSION_EXPIRATION_AT = 'session_expiration_at'

    get id() {
        return this._getAttribute(UserSessionModel.ATTR_ID)
    }

    get userId() {
        return this._getAttribute(UserSessionModel.ATTR_USER_ID)
    }

    get accessToken() {
        return this._getAttribute(UserSessionModel.ATTR_ACCESS_TOKEN)
    }

    get refreshToken() {
        return this._getAttribute(UserSessionModel.ATTR_REFRESH_TOKEN)
    }

    get sessionExpirationAt() {
        return this._getAttribute(UserSessionModel.ATTR_SESSION_EXPIRATION_AT)
    }

}
