import type { ApiDateTime, ComputedPrices, Monetary } from '../types/general-data'
import { ApiModel } from '@composable-api/api.model'
import { ProductAvailabilityModel } from './product-availability.model'
import type {
    ProductVariationPropertyAttributeModel
} from '../models/custom/product-variation-property-attribute.model'

interface Attributes {
    [ProductVariationModel.ATTR_ID]: number
    [ProductVariationModel.ATTR_NAME]: string | null
    [ProductVariationModel.ATTR_PRODUCT_ID]: number
    [ProductVariationModel.ATTR_IMAGE_ID]: number | null
    [ProductVariationModel.ATTR_PRODUCT_AVAILABILITY_ID]: number | null
    [ProductVariationModel.ATTR_CODE]: string | null
    [ProductVariationModel.ATTR_SKU]: string | null
    [ProductVariationModel.ATTR_EAN]: string | null
    [ProductVariationModel.ATTR_UPC]: string | null
    [ProductVariationModel.ATTR_JAN]: string | null
    [ProductVariationModel.ATTR_ISBN]: string | null
    [ProductVariationModel.ATTR_MPN]: string | null
    [ProductVariationModel.ATTR_UPDATE_STOCK]: boolean
    [ProductVariationModel.ATTR_STOCK_STATE]: number | null
    [ProductVariationModel.ATTR_POSITION]: number
    [ProductVariationModel.ATTR_IS_ACTIVE]: boolean
    [ProductVariationModel.ATTR_HEIGHT]: number | null
    [ProductVariationModel.ATTR_WIDTH]: number | null
    [ProductVariationModel.ATTR_DEPTH]: number | null
    [ProductVariationModel.ATTR_WEIGHT]: number | null
    [ProductVariationModel.ATTR_VOLUME]: number | null
    [ProductVariationModel.ATTR_NEED_PACKAGE]: boolean
    [ProductVariationModel.ATTR_PRICE]: Monetary | null
    [ProductVariationModel.ATTR_TAXED_PRICE]: Monetary | null
    [ProductVariationModel.ATTR_EXPECTED_COUNT]: number
    [ProductVariationModel.ATTR_EXPECTED_DATE_MAX]: ApiDateTime | null
    [ProductVariationModel.ATTR_EXPECTED_DATE_MIN]: ApiDateTime | null
    [ProductVariationModel.ATTR_RESERVED_STATE]: number | null
    [ProductVariationModel.ATTR_PURCHASABLE_SINCE]: ApiDateTime | null
    [ProductVariationModel.ATTR_IS_DEFAULT]: boolean
    [ProductVariationModel.ATTR_MAX_PURCHASABLE_AMOUNT]: number | null
    [ProductVariationModel.ATTR_IS_HIDDEN]: boolean
}

interface Embeds {
    [ProductVariationModel.EMBED_STOCK_STATES]: ProductStockState[]
    [ProductVariationModel.EMBED_COMPUTED_TAGS]: Monetary | null
    [ProductVariationModel.EMBED_TAG_IDS]: number[]
    [ProductVariationModel.EMBED_DISCOUNTED_PRICE]: Monetary | null
    [ProductVariationModel.EMBED_DISCOUNTED_TAXED_PRICE]: Monetary | null
    [ProductVariationModel.EMBED_CUSTOMER_PRICE]: Monetary | null
    [ProductVariationModel.EMBED_CUSTOMER_TAXED_PRICE]: Monetary | null
    [ProductVariationModel.EMBED_PROPERTIES]: {
        product_property_id: number
        product_property_attribute_id: number | null
        attribute_name: string | null
        property_name: string | null
    }[]
    [ProductVariationModel.EMBED_PRICES]: Monetary | null
    [ProductVariationModel.EMBED_COMPUTED_PRICES]: Monetary | null
    [ProductVariationModel.EMBED_COMPUTED_TAXED_PRICES]: ComputedPrices
    [ProductVariationModel.EMBED_DISCOUNT_PERCENTS]: number | null
    [ProductVariationModel.EMBED_PRODUCT_AVAILABILITY]: ProductAvailabilityModel
    [ProductVariationModel.EMBED_IMAGE_URL]: string | null
}

export class ProductVariationModel extends ApiModel<Attributes, Embeds> {
    static key = 'ProductVariationModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_PRODUCT_ID = 'product_id'
    static readonly ATTR_IMAGE_ID = 'image_id'
    static readonly ATTR_PRODUCT_AVAILABILITY_ID = 'product_availability_id'
    static readonly ATTR_CODE = 'code'
    static readonly ATTR_SKU = 'sku'
    static readonly ATTR_EAN = 'ean'
    static readonly ATTR_UPC = 'upc'
    static readonly ATTR_JAN = 'jan'
    static readonly ATTR_ISBN = 'isbn'
    static readonly ATTR_MPN = 'mpn'
    static readonly ATTR_UPDATE_STOCK = 'update_stock'
    static readonly ATTR_STOCK_STATE = 'stock_state'
    static readonly ATTR_POSITION = 'position'
    static readonly ATTR_IS_ACTIVE = 'is_active'
    static readonly ATTR_HEIGHT = 'height'
    static readonly ATTR_WIDTH = 'width'
    static readonly ATTR_DEPTH = 'depth'
    static readonly ATTR_WEIGHT = 'weight'
    static readonly ATTR_VOLUME = 'volume'
    static readonly ATTR_NEED_PACKAGE = 'need_package'
    static readonly ATTR_PRICE = 'price'
    static readonly ATTR_TAXED_PRICE = 'taxed_price'
    static readonly ATTR_EXPECTED_COUNT = 'expected_count'
    static readonly ATTR_EXPECTED_DATE_MAX = 'expected_date_max'
    static readonly ATTR_EXPECTED_DATE_MIN = 'expected_date_min'
    static readonly ATTR_RESERVED_STATE = 'reserved_state'
    static readonly ATTR_PURCHASABLE_SINCE = 'purchasable_since'
    static readonly ATTR_IS_DEFAULT = 'is_default'
    static readonly ATTR_MAX_PURCHASABLE_AMOUNT = 'max_purchasable_amount'
    static readonly ATTR_IS_HIDDEN = 'is_hidden'

    static readonly EMBED_STOCK_STATES = 'stock_states'
    static readonly EMBED_COMPUTED_TAGS = 'tags'
    static readonly EMBED_TAG_IDS = 'tag_ids'
    static readonly EMBED_DISCOUNTED_PRICE = 'discounted_price'
    static readonly EMBED_DISCOUNTED_TAXED_PRICE = 'discounted_taxed_price'
    static readonly EMBED_CUSTOMER_PRICE = 'customer_price'
    static readonly EMBED_CUSTOMER_TAXED_PRICE = 'customer_taxed_price'
    static readonly EMBED_PROPERTIES = 'properties'
    static readonly EMBED_PRICES = 'prices'
    static readonly EMBED_COMPUTED_PRICES = 'computed_prices'
    static readonly EMBED_COMPUTED_TAXED_PRICES = 'computed_taxed_prices'
    static readonly EMBED_DISCOUNT_PERCENTS = 'discount_percents'
    static readonly EMBED_PRODUCT_AVAILABILITY = 'product_availability'
    static readonly EMBED_IMAGE_URL = 'image_url'

    get id() {
        return this._getAttribute(ProductVariationModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(ProductVariationModel.ATTR_NAME)
    }

    get productId() {
        return this._getAttribute(ProductVariationModel.ATTR_PRODUCT_ID)
    }

    get imageId() {
        return this._getAttribute(ProductVariationModel.ATTR_IMAGE_ID)
    }

    get productAvailabilityId() {
        return this._getAttribute(ProductVariationModel.ATTR_PRODUCT_AVAILABILITY_ID)
    }

    get code() {
        return this._getAttribute(ProductVariationModel.ATTR_CODE)
    }

    get sku() {
        return this._getAttribute(ProductVariationModel.ATTR_SKU)
    }

    get ean() {
        return this._getAttribute(ProductVariationModel.ATTR_EAN)
    }

    get upc() {
        return this._getAttribute(ProductVariationModel.ATTR_UPC)
    }

    get jan() {
        return this._getAttribute(ProductVariationModel.ATTR_JAN)
    }

    get isbn() {
        return this._getAttribute(ProductVariationModel.ATTR_ISBN)
    }

    get mpn() {
        return this._getAttribute(ProductVariationModel.ATTR_MPN)
    }

    get updateStock() {
        return this._getAttribute(ProductVariationModel.ATTR_UPDATE_STOCK)
    }

    /**
     * The number of items currently in stock.
     */
    get stockState() {
        return this._getAttribute(ProductVariationModel.ATTR_STOCK_STATE)
    }

    get position() {
        return this._getAttribute(ProductVariationModel.ATTR_POSITION)
    }

    get isActive() {
        return this._getAttribute(ProductVariationModel.ATTR_IS_ACTIVE)
    }

    get height() {
        return this._getAttribute(ProductVariationModel.ATTR_HEIGHT)
    }

    get width() {
        return this._getAttribute(ProductVariationModel.ATTR_WIDTH)
    }

    get depth() {
        return this._getAttribute(ProductVariationModel.ATTR_DEPTH)
    }

    get weight() {
        return this._getAttribute(ProductVariationModel.ATTR_WEIGHT)
    }

    get volume() {
        return this._getAttribute(ProductVariationModel.ATTR_VOLUME)
    }

    get needPackage() {
        return this._getAttribute(ProductVariationModel.ATTR_NEED_PACKAGE)
    }

    get price() {
        return this._getAttribute(ProductVariationModel.ATTR_PRICE)
    }

    get taxedPrice() {
        return this._getAttribute(ProductVariationModel.ATTR_TAXED_PRICE)
    }

    get expectedCount() {
        return this._getAttribute(ProductVariationModel.ATTR_EXPECTED_COUNT)
    }

    get expectedDateMax() {
        return this._getAttribute(ProductVariationModel.ATTR_EXPECTED_DATE_MAX)
    }

    get expectedDateMin() {
        return this._getAttribute(ProductVariationModel.ATTR_EXPECTED_DATE_MIN)
    }

    get reservedState() {
        return this._getAttribute(ProductVariationModel.ATTR_RESERVED_STATE)
    }

    get purchasableSince() {
        return this._getAttribute(ProductVariationModel.ATTR_PURCHASABLE_SINCE)
    }

    get isDefault() {
        return this._getAttribute(ProductVariationModel.ATTR_IS_DEFAULT)
    }

    get maxPurchasableAmount() {
        return this._getAttribute(ProductVariationModel.ATTR_MAX_PURCHASABLE_AMOUNT)
    }

    get isHidden() {
        return this._getAttribute(ProductVariationModel.ATTR_IS_HIDDEN)
    }

    /**
     * The stock state in different warehouses.
     */
    get stockStates() {
        return this._getEmbed(ProductVariationModel.EMBED_STOCK_STATES)
    }

    get computedTags() {
        return this._getEmbed(ProductVariationModel.EMBED_COMPUTED_TAGS)
    }

    get tagIds() {
        return this._getEmbed(ProductVariationModel.EMBED_TAG_IDS)
    }

    get discountedPrice() {
        return this._getEmbed(ProductVariationModel.EMBED_DISCOUNTED_PRICE)
    }

    get discountedTaxedPrice() {
        return this._getEmbed(ProductVariationModel.EMBED_DISCOUNTED_TAXED_PRICE)
    }

    get customerPrice() {
        return this._getEmbed(ProductVariationModel.EMBED_CUSTOMER_PRICE)
    }

    get customerTaxedPrice() {
        return this._getEmbed(ProductVariationModel.EMBED_CUSTOMER_TAXED_PRICE)
    }

    /**
     * The property x attribute mappings of the product variation.
     *
     * Properties are the product variation properties which differentiate the variations. (e.g. color)
     * (They exclude the product properties which are common to all variations of the product.)
     *
     * The values of the properties are the attributes of the properties. (e.g. red, blue)
     *
     * !! Note !!!
     * Consider using the `getValidProperties` method instead of this property, because
     * this property returns mappings for all properties, including the ones that do not have any
     * attributes set. (will have `null` as the attribute id)
     * @see getValidProperties
     * @private
     */
    private get properties() {
        return this._getEmbed(ProductVariationModel.EMBED_PROPERTIES)
    }

    get prices() {
        return this._getEmbed(ProductVariationModel.EMBED_PRICES)
    }

    get computedPrices() {
        return this._getEmbed(ProductVariationModel.EMBED_COMPUTED_PRICES)
    }

    get computedTaxedPrices() {
        return this._getEmbed(ProductVariationModel.EMBED_COMPUTED_TAXED_PRICES)
    }

    get discountPercents() {
        return this._getEmbed(ProductVariationModel.EMBED_DISCOUNT_PERCENTS)
    }

    get productAvailability() {
        return this._getEmbed(ProductVariationModel.EMBED_PRODUCT_AVAILABILITY, ProductAvailabilityModel)
    }

    get imageUrl() {
        return this._getEmbed(ProductVariationModel.EMBED_IMAGE_URL)
    }


    // ---------------------------------------------------------------------------------------------------------------------

    isDiscounted() {
        return this.discountPercents !== null && this.discountedTaxedPrice !== null
    }

    /**
     * Whether the product variation is available or not.
     * Being available doesn't necessarily mean that the product variation can be
     * added to the cart & purchased. To determine whether it can be purchased,
     * use `canBePurchased()` instead.
     *
     * @returns `true` if the product variation is available according to its set availability or when no availability
     * is set, `false` otherwise.
     * @see canBePurchased
     */
    isAvailable() {
        return this.productAvailability?.canPurchase ?? true
    }

    /**
     * Whether the product variation can be **purchased**.
     *
     * !! Note !!
     * This method is different from `isAvailable()` which only
     * returns the state of the product variation availability without
     * checking the stock state.
     *
     * @see isAvailable
     */
    canBePurchased() {
        const hasStock = this.stockState === null || this.stockState > 0
        return this.isAvailable() && (!this.updateStock || hasStock)
    }

    /**
     * Whether the product variation has the given product variation attributes.
     * If any attribute in the array is `null`, it will always return `true`.
     * @param attributes
     */
    hasAttributes(attributes: (ProductVariationPropertyAttributeModel | null)[]) {
        const attrs = attributes.filter(Boolean) as ProductVariationPropertyAttributeModel[]
        if (!attrs.length) {
            return true
        }

        return attrs.every((attribute) => {
            return this.properties?.some(
                property =>
                    property.product_property_id === attribute.productPropertyId &&
                    property.product_property_attribute_id === attribute.id
            )
        })
    }

    /**
     * Whether the product variation has the given product variation attribute.
     * If the attribute is `null`, it will always return `true`.
     * @param attribute the attribute to check
     */
    hasAttribute(attribute: ProductVariationPropertyAttributeModel | null) {
        return this.hasAttributes([attribute])
    }

    /**
     * The property x attribute mappings of the product variation.
     *
     * This method returns **only the properties that are valid** - i.e. have the attribute set.
     *
     * Properties are the product variation properties which differentiate the variations. (e.g. color)
     * (They exclude the product properties which are common to all variations of the product.)
     *
     * The values of the properties are the attributes of the properties. (e.g. red, blue)
     */
    getValidProperties() {
        return this.properties?.filter(property =>
            property.product_property_attribute_id !== null && property.product_property_id !== null
        ) ?? []
    }
}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================

interface ProductStockState {
    state: number
    stock_id: number
}
