import { ApiModel } from '@composable-api/api.model'
import type { FormattedCurrency, Monetary } from '@sim-api-types/general-data'

interface Attributes {
    price: Monetary
}

export class FreeShippingModel extends ApiModel<Attributes> {
    static key = 'FreeShippingModel'

    static readonly ATTR_PRICE = 'price'

    get price() {
        return this._getAttribute(FreeShippingModel.ATTR_PRICE)
    }

    /**
     * Get the price for a specific currency code.
     *
     * Returns `null` if the price is not present for the currency.
     * @deprecated Use the `price` attribute directly in themes, this should only
     * be used for one specific case, when fetching the data on the server during setup
     * (we do not know the currency at that point, so we need to fetch all of them).
     * @param code The currency code
     */
    getPriceForCurrency(code: string): FormattedCurrency | null {
        if (typeof this.price !== 'object' || !this.price) return null
        if ('value' in this.price || 'formatted' in this.price) return null

        const result = this.price[code]
        if (typeof result !== 'object') return null

        return result
    }
}
