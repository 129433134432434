import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [SearchTermModel.ATTR_TERM]: string
}

interface Embeds {
}

// This model is not in response-serialization. Add when necessary.

export class SearchTermModel extends ApiModel<Attributes, Embeds> {
    static key = 'SearchTermModel'

    static readonly ATTR_TERM = 'term'

    get term() {
        return this._getAttribute(SearchTermModel.ATTR_TERM)
    }
}
