import { ApiModel } from '@composable-api/api.model'
import { NewsletterType } from '../enums/newsletter'

interface Attributes {
    [NewsletterModel.ATTR_ID]: number
    [NewsletterModel.ATTR_CUSTOMER_ID]: number | null
    [NewsletterModel.ATTR_EMAIL]: string | null
    [NewsletterModel.ATTR_TYPE]: NewsletterType
    [NewsletterModel.ATTR_GENDER_ORIENTED_PRODUCT_PREFERENCE]: GenderOrientedProductPreference | null
    [NewsletterModel.ATTR_CREATED_AT]: string
}

export class NewsletterModel<TAttributes = unknown> extends ApiModel<Attributes & TAttributes> {
    static key = 'NewsletterModel'

    static readonly ATTR_ID = 'id'
    static readonly ATTR_CUSTOMER_ID = 'customer_id'
    static readonly ATTR_EMAIL = 'email'
    static readonly ATTR_TYPE = 'type'
    static readonly ATTR_GENDER_ORIENTED_PRODUCT_PREFERENCE = 'gender_oriented_product_preference'
    static readonly ATTR_CREATED_AT = 'created_at'

    get id() {
        return this._getAttribute(NewsletterModel.ATTR_ID)
    }

    get customerId() {
        return this._getAttribute(NewsletterModel.ATTR_CUSTOMER_ID)
    }

    get email() {
        return this._getAttribute(NewsletterModel.ATTR_EMAIL)
    }

    get genderOrientedProductPreference() {
        return this._getAttribute(NewsletterModel.ATTR_GENDER_ORIENTED_PRODUCT_PREFERENCE)
    }

    get type() {
        return this._getAttribute(NewsletterModel.ATTR_TYPE)
    }

    get createdAt() {
        return this._getAttribute(NewsletterModel.ATTR_CREATED_AT)
    }
}

// ----------------------------------------------

export enum GenderOrientedProductPreference {
    MALE = 0,
    FEMALE = 1,
}
